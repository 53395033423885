import React from 'react'
import AccordionCustom from '../../../components/Accordion/AccordionCustom'

const FamilyLoanConditions = () => {
	return (
		<div className="family_loan_plans">
			<div className="container">
				<div className="title">Умови надання сімейного кредиту</div>
				<AccordionCustom
					title="Вимоги до позичальника"
					children={<div>
						Пропонуємо сімейний кредит тим, у кого:
						<ul className="ul">
							<li>в сім'ї підростає хоча б одна дитина до 18 років;</li>
							<li>немає порушень термінів оплати;</li>
							<li>відсутні зобов'язання за швидкими кредитами;</li>
							<li>поточні щомісячні фінансові зобов'язання менше ніж 50000 грн;</li>
						</ul>
						Для клопотання про кредит ви повинні мати офіційний постійний доход в гривні, починаючи з 15000 гривень на місяць.
					</div>}
					id="family-loan-header-panel1"
					ariaControls="family-loan-content-panel1"
					defaultExpanded
				/>

				<AccordionCustom
					title="Подати заявку"
					children="Для подання клопотання слід заповнити форму клопотання, за необхідності подати копію документа, що засвідчує особу, та виписку банківського рахунку. Наш спеціаліст із кредитів зв'яжеться з Вами для уточнення необхідних документів та оформлення договору. Під час подання клопотання на веб-сторінці отримайте відповідь протягом декількох хвилин. Процес кредитування є повністю онлайн, кредитні суми  надійдуть на рахунок протягом вісьми хвилин, незалежно від часу доби та дня тижня."
					id="family-loan-header-panel2"
					ariaControls="family-loan-content-panel2"
				/>

				<AccordionCustom
					title="Підписати договір"
					children="Найзручніше підписати кредитний договір із  допомогою єлектроного підпису  . Також договір можна підписати у нашому офісі. Укладання договору про сімейний кредит у Groshi247і безкоштовно."
					id="family-loan-header-panel2"
					ariaControls="family-loan-content-panel2"
				/>
			</div>
		</div>
	);
};

export default FamilyLoanConditions;