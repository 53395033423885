import React, { useState } from "react";
import "./TermsUsingCredit.scss";
import PhoneComponent from "../Common/PhoneComponent";

const TermsUsingCredit = ({ content }) => {
    const [showTerm, setShowTerm] = useState(false);

    return (
        <div className="terms_using_credit">
            <div className="container">
                <div className="title">УМОВИ КОРИСТУВАННЯ КРЕДИТОМ ДУЖЕ ПРОСТІ:</div>
                <div className="terms_using_credit__wrapper">
                    <div className={`terms_using_credit__info ${showTerm ? 'active' : ''}`}>
                        {content ? content : (
                            <>
                                Мінімальна сума споживчого кредиту становить - 3 000,00 грн, максимальна сума/ліміт споживчого кредиту - 6 000,00 грн в залежності від обраного кредитного продукту.
                                <br/>
                                <br/>
                                Відсотки нараховуються за кожен фактичний день користування кредитом. Кредит можна погасити як в день, визначений в графіку погашення кредиту, так і раніше строку. За дострокове погашення кредиту не буде застосовано жодних штрафних санкцій - Ви просто сплачуєте відсотки за дні фактичного користування кредитом.
                                <br/>
                                <br/>
                                Споживач має можливість отримати грошові кошти у кредит, на споживчі цілі, під заставу або поруку третьої особи за фіксованою процентною ставкою. Сума кредиту, строк, вартість послуги та інші умови узгоджуються сторонами шляхом вибору клієнтом запропонованих доступних параметрів позики та закріплюються договором, укладеним в електронній формі відповідно до Закону України «Про електронну комерцію».
                                <br/>
                                <br/>
                                Надання коштів в кредит не передбачає надання Товариством додаткових фінансових послуг Споживачам.
                                <br/>
                                <br/>
                                Надання коштів в кредит не передбачає сплати податків і зборів за рахунок фізичної особи в результаті отримання фінансової послуги.
                                <br/>
                                <br/>
                                У випадку оформлення застави по споживчому кредиту клієнт може понести додаткові витрати на нотаріальне оформлення договору застави та/або страхування предмету застави, моніторинг стану предмета застави протягом часу знаходження його в заставі. Величина таких додаткових витрат не визначається Товариством і залежить від вартості предмета, оформленого в заставу, і страхового тарифу на цей предмет застави, який визначається обраною Споживачем страховою компанією, вартості нотаріальних послуг кожного конкретного нотаріуса.
                                <br/>
                                <br/>
                                Фінансова послуга надається Споживачам на умовах, визначених відповідним договором про споживче кредитування. У разі надходження на рахунок Товариства грошових коштів від Споживача в достатньому обсязі для погашення його кредиту, зобов’язання Споживача вважаються належно виконаними і в такому випадку відповідний договір про споживчий кредит припиняє свою дію з дати зарахування грошових коштів на рахунок Товариства.
                                <br/>
                                <br/>
                                Протягом 14 календарних днів з дня укладення відповідного договору про споживчий кредит Споживач має право відмовитися від договору без пояснення причин, у тому числі в разі отримання ним грошових коштів.
                                <br/>
                                <br/>
                                У такому випадку Споживач зобов’язаний повернути суму отриманого кредиту та нараховані проценти за користування кредитом відповідно до кількості днів користування. Про намір відмовитися від договору про споживчий кредит Споживач повинен повідомити Товариство у письмовій формі (у паперовому або електронному вигляді з накладенням електронних підписів, електронних цифрових підписів, інших аналогів власноручних підписів (печаток) сторін у порядку, визначеному законодавством). Протягом семи календарних днів з дати подання письмового повідомлення про відмову від договору про споживчий кредит, Споживач зобов’язаний повернути грошові кошти, одержані згідно з договорм про споживчий кредит, та сплатити проценти за період з дня одержання коштів до дня їх повернення за процентною ставкою, встановленою відповідним договором про споживчий кредит.
                                <br/>
                                <br/>
                                Розмір фіксованої процентної ставки, встановлений відповідним договором про споживчий кредит, не може бути збільшений без письмової згоди Споживача.
                                <br/>
                                <br/>
                                У випадку дострокового повернення кредиту, Споживач зобов’язується повернути Товариству суму кредиту та сплатити проценти за фактичну кількість днів користування кредитом. Плата за факт дострокового повернення кредиту відсутня.
                                <br/>
                                <br/>
                                За ініціативою Споживача договір про споживчий кредит може бути розірваний в судовому порядку, за умов виконання ним зобов’язань за відповідним договором про споживчий кредит.
                                <br/>
                                <br/>
                                Споживач має право після припинення дії договору про надання споживчого кредиту, включно у зв’язку із завершенням строку дії, розірванням або виконанням такого договору, звернутися до Товариства із запитом на надання інформації що стосується виконання сторонами своїх зобов’язань, установлених договором, включаючи отримання інформації (у формі довідки) про відсутність заборгованості та виконання зобов’язань споживача за договором у повному обсязі, яку Товариство надає у формі паперового та/або електронного документа (за вибором Споживача) протягом п’яти робочих днів із дня отримання Товариством такого запиту.
                                <br/>
                                <br/>
                                Для вирішення спірних питань у досудовому порядку, Споживачі мають змогу звернутися безпосередньо до Товариства, викласти в чіткій і зрозумілій формі свої зауваження, які розглядаються Товариством у порядку та у строки, встановлені Законом України «Про звернення громадян». Крім цього, споживачі мають змогу звернутися до суду за захистом своїх прав та до Національного банку України, веб-сторінка: <a href="https://bank.gov.ua" target="_blank">bank.gov.ua</a>
                                <br/>
                                <br/>
                                Гарантійний фонд в Товариство з обмеженою відповідальністю «Гроші 247» і компенсаційні схеми відсутні.
                                <br/>
                                <br/>
                                <div className="terms_using_credit__block_info">
                                    Товариство з обмеженою відповідальністю «Гроші 247» попереджає Споживача про те, що:
                                    <br/>
                                    <br/>
                                    В разі невиконання обовʼязків згідно з договором про споживчий кредит до Споживача можуть бути застосовані штраф/пеня, а також інші платежі, передбачені договором про споживчий кредит.
                                    <br/>
                                    <br/>
                                    Порушення виконання зобов’язання щодо повернення кредиту у передбачені договором про споживчий кредит строки може вплинути на кредитну історію та ускладнити отримання кредиту надалі;
                                    <br/>
                                    <br/>
                                    Товариству з обмеженою відповідальністю «Гроші 247» забороняється вимагати від Споживача придбання будь-яких товарів чи послуг від Товариства з обмеженою відповідальністю «Гроші 247» або спорідненої чи повʼязаної з нею особи як обовʼязкову умову надання кредиту;
                                    <br/>
                                    <br/>
                                    Для прийняття усвідомленого рішення щодо отримання кредиту на запропонованих умовах Споживач має право розглянути альтернативні різновиди споживчих кредитів та фінансових установ;
                                    <br/>
                                    <br/>
                                    Товариство з обмеженою відповідальністю «Гроші 247» має право вносити зміни до укладених зі Споживачами договорів про споживчий кредит тільки за взаємною згодою сторін у спосіб та на умовах, визначених договором про споживчий кредит;
                                    <br/>
                                    <br/>
                                    Споживач має право відмовитися від отримання рекламних матеріалів Товариства засобами дистанційних каналів комунікації;
                                    <br/>
                                    <br/>
                                    Можливі витрати на сплату Споживачем платежів за користування споживчим кредитом в залежності від обраного Споживачем способу сплати;
                                    <br/>
                                    <br/>
                                    Споживач має право на продовження (лонгації, пролонгації) строку погашення кредиту (строку виконання грошового зобов’язання)/строку кредитування/строку дії договору про кредит. Ці дії здійснюються в Особистому кабінеті Споживача, без змін або зі зміною умов попередньо укладеного договору в бік погіршення для Споживача із зазначенням переліку та цифрового значення умов, що підлягають зміні та залежить від виду договору про споживче кредитування;
                                    <br/>
                                    <br/>
                                    Споживач повинен негайно інформувати Товариство з обмеженою відповідальністю «Гроші 247» про несанкціонований доступ або зміну інформації про себе в системах дистанційного обслуговування Товариства з обмеженою відповідальністю «Гроші 247».
                                    <br/>
                                    <br/>
                                    Особи, які належать до захищених категорій осіб, мають право повідомити Товариство про свій статус, використовуючи будь-який канал зв’язку, зазначений на офіційному сайті Товариства.
                                </div>
                                <br/>
                                <br/>
                                Реквізити органів, які здійснюють державне регулювання ринків фінансових послуг та з питань захисту прав споживачів:
                                <br/>
                                <br/>
                                Національний банк України.
                                <br/>
                                <br/>
                                Телефон:
                                {' '}
                                <PhoneComponent/>
                                {' '}
                                (у разі, якщо Ви не додзвонилися до оператора і всі лінії були зайняті або Ви телефонуєте в неробочий день та час, – залиште Ваш номер телефону на автовідповідач і ми Вам зателефонуємо).
                                <br/>
                                <br/>
                                Електронна пошта: nbu@bank.gov.ua (заповніть і надішліть форму та за потреби додайте супровідні матеріали. Але розмір вкладення – не більше 10 МБ).
                                <br/>
                                <br/>
                                Веб-сторінка: <a href="https://bank.gov.ua" target="_blank">bank.gov.ua</a>
                                <br/>
                                <br/>
                                Для листування: вул. Інститутська, 9, м. Київ, 01601.
                                <br/>
                                <br/>
                                Для подання письмових звернень громадян: вул. Інститутська, 11-б, м. Київ, 01601.
                                <br/>
                                <br/>
                                Державна служба України з питань безпеки харчових продуктів та захисту споживачів
                                <br/>
                                <br/>
                                Адреса: 01001, м. Київ, вул. Бориса Грінченка, 1, тел. 
                                {' '}
                                <PhoneComponent/>
                                <br />
                                <br />
                                <span style={{ textAlign: 'center' }}>ВІДСОТКИ:</span>
                                <br />
                                <br />
                                В Groshi247 діє 2 типи кредитних програм: для нових клієнтів та для тих, хто вже брав у нас кредит і успішно його повернув.
                                <br />
                                <br />
                                Програма для нових клієнтів дозволяє взяти кредит від 3 000 до 6 000 грн на строк до 30 днів, в залежності від обрано фінансового продукту. З детальними умовами кожного продукту можна ознайомитись в паспортах відповідних продуктів, розміщених на офіційному сайті Товариства.
                                <br />
                                <br />
                                Відсотки такі:
                                <br />
                                <br />
                                - 1% від суми кредита в день протягом визначеного договором строку користування кредитом.
                                <br />
                                <br />
                                - 1% від суми кредита в день за кожен день користування кредитом після завершення іизначеного договором строку користування кредитом.
                                <br />
                                <br />
                                Розберемо на прикладі:
                                <br />
                                <br />
                                Петро хоче взяти кредит в розмірі 3000 грн на 4 дні.
                                <br />
                                <br />
                                Кожний день користування таким кредитом коштуватиме йому 3000*1%=30 грн
                                <br />
                                <br />
                                Загалом наприкінці терміну Петро сплатить 3000 грн + 30*4 грн = 3120 грн
                                <br />
                                <br />
                                Для повторних позичальників доступні більш лояльні умови та більші суми позик.
                            </>
                        )}
                    </div>
                    <div className="terms_using_credit__border" />
                    <button className="btn btn__pink" onClick={() => setShowTerm(!showTerm)}>
                        {showTerm ? 'Сховати' : 'Читати повністю'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TermsUsingCredit;