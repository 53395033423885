import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ScrollLink from "../lib/helpers/ScrollLink";

const data = [
    {
        title: 'На яку суму можна отримати кредит на картку на сайті Groshi 24/7?',
        children: 'Ви можете отримати кредит онлайн на суму від 1 000,00 грн до 50 000,00 грн Термін кредитування може бути обраний у межах від 7 днів до 120 днів, в залежності від кредитного рейтинга позичальника.'
    },
    {
        title: 'Що потрібно для оформлення мікрозайму?',
        children: <div>
            Для отримання кредиту, зазвичай потрібно наступне:
            <br />
            1. Комп'ютер або мобільний телефон з доступом до Інтернету
            <br />
            2. Банківська картка будь-якого банку України, оформлена на ваше ім'я. Вона може використовуватися для отримання коштів або погашення кредиту.
            <br />
            3. Паспорт для ідентифікації вас як заявника кредиту.
            <br />
            4. Ідентифікаційний код - це унікальний ідентифікатор, присвоєний кожному громадянину України.
        </div>
    },
    {
        title: 'До якого віку дають споживчий кредит?',
        children: 'Вимоги до позичальників: громадянство України, вік від 18 до 60 років, відсутність значних прострочень у кредитній історії.'
    },
    {
        title: 'У якому місті можна отримати кредит?',
        children: 'На підконтрольній території України. Кредит оформляється на сайті, кошти перераховуються на вашу банківську карту.'
    },
    {
        title: 'Щоб підписати договір мені треба відвідувати ваш офіс?',
        children: 'Ні, вам не потрібно відвідувати офіс Гроші 24/7 для підписання договору. Вся процедура оформлення кредиту здійснюється он-лайн. Ви можете подати заявку через Інтернет, і договір буде підписано в електронному вигляді. Після підписання договору кошти буде перераховано на вказану вами банківську картку.'
    },
    {
        title: 'Які документи необхідні для отримання кредиту в Гроші 24/7?',
        children: <div>
            Для отримання он-лайн кредиту в Гроші 24/7 вам необхідно мати наступні документи:
            <br />
            <br />
            Паспорт. Дані з вашого паспорта будуть використовуватися для ідентифікації вас як заявника.
            <br />
            <br />
            Ідентифікаційний код, який також буде використовуватися для ідентифікації вас як заявника.
            <br />
            <br />
            Під час підписання Договору вам не потрібно сканувати будь-які документи, оскільки вся процедура здійснюється он-лайн. Під час первинного подання заявки ви заповнюєте анкету і вказуєте данні вашого паспорта та ідентифікаційного коду. Під час наступних позик ваші дані будуть збережені, і ви зможете отримувати кошти, вказуючи тільки суму і термін кредиту без необхідності повторної ідентифікації.
        </div>
    },
    {
        title: 'Для отримання кредиту потрібні застави, поручителі або довідка про доходи?',
        children: <div>
            Гроші 24/7, як правило, видає кредити на споживчі потреби без застави та поручителів і без довідки про доходи. Беззаставний он-лайн кредит не потребує вищевказаних документів. Отримання кредиту без довідки про доходи ґрунтуються на інших критеріях, таких як ваша особиста кредитна історія, спроможність повернути кредит та інші фактори.
            <br />
            <br />
            Кредит без поручителів може бути зручний для тих, хто не може надати заставу або поручителя, або не хоче ділитися своїми даними про доходи. Однак, важливо пам'ятати, що через ці фактори відсоткові ставки на беззаставні кредити вищі. Тим самим компенсується ризик, пов'язаний з відсутністю застави або поручителя.
        </div>
    },
    {
        title: 'На яку картку можна отримати кредит?',
        children: "Ви можете отримати кредит на картку будь-якого українського банку, за умови, що ця картка активна і оформлена на ваше ім'я."
    },
    {
        title: 'Чи можу я отримати кредит у Гроші 24/7, якщо у мене вже є кредит в іншій організації?',
        children: 'Так, за умови, що у вас не було значних заборгованостей і прострочень в обслуговуванні попередніх позик.'
    },
    {
        title: 'Скільки кредитив одночасно я можу оформити в Гроші 24/7?',
        children: 'На сьогодні в сервісі Гроші 24/7 ви не зможете отримати одночасно декілька кредитів. Новий кредит ви зможете отримати тільки після повного погашення попереднього.'
    },
    {
        title: 'Як оформити заявку на кредит?',
        children: <div>
            Процедура оформлення кредиту – максимально проста. Для подачі заявки вам необхідно перейти за посиланням <ScrollLink to="/">https://groshi247.com/</ScrollLink> і виконати 4 прості кроки:
            <br />
            1. Обрати суму й строк погашення кредиту за допомогою відповідних “бігунків” на кредитному калькуляторі й натиснути “Оформити кредит”;
            <br />
            2. Зареєструватися на сайті, пройшовши ідентифікацію через систему BankID, і заповнити заявку;
            <br />
            3. Підписати кредитний договір, ввівши код-ідентифікатор, який ви отримаєте SMS-повідомленням;
            <br />
            4. Подати заявку й отримати рішення.
            <br />
            <br />
            ВСЕ!
            <br />
            Обробка даних повністю автоматизована, і в разі позитивного рішення по заявці кредитні кошти надходять на вашу картку вже через 5 хвилин!
        </div>
    },
    {
        title: 'Скільки часу розглядається заявка та перераховуються кошти на картку?',
        children: 'Процес прийняття рішень повністю автоматизований. Це означає, що рішення про видачу кредиту система приймає протягом 2-10 секунд. Після ухвалення позитивного рішення гроші будуть зараховані на вашу картку за 2 хвилини.'
    },
    {
        title: 'Як оформити кредит по телефону?',
        children: 'Звичайно, ви можете зателефонувати до кол-центру компанії і отримати відповіді на ваші запитання, щодо оформлення позики. Але сам процес оформлення кредиту обов\'язково повинен бути завершений вами на сайті.'
    },
    {
        title: 'Що робити, якщо я вказав невірні дані в заявці?',
        children: 'Не хвилюйтеся, якщо ви вказали невірні дані в заявці. Просто увійдіть у свій особистий кабінет на сайті та внесіть необхідні корективи у свої дані. Якщо у вас виникнуть додаткові запитання, не соромтеся ставити їх телефоном.'
    },
    {
        title: 'Як дізнатися рішення за кредитом?',
        children: 'Ви отримаєте рішення за кредитом одразу після заповнення заявки прямо на сайті. Крім того, позитивне рішення буде надіслано на вашу електронну адресу та у вигляді SMS-повідомлення.'
    },
    {
        title: 'Навіщо проводити перевірку картки?',
        children: 'Банківська картка повинна належати тільки вам для того, щоб шахраї не могли оформити кредит з видачею на чужу картку.'
    },
    {
        title: 'Що таке CVV/CVC-код, і навіщо його вводити під час оформлення заявки?',
        children: 'CVV / CVC-код (Card Verification Value / Card Verification Code) - це тризначний код на зворотному боці вашої банківської картки. Він використовується для перевірки автентичності картки під час проведення операцій в Інтернеті. Введення CVV/CVC-коду під час оформлення заявки дає змогу додатково захистити транзакцію від шахрайства.'
    },
    {
        title: 'Як ознайомитися з умовами кредитного договору?',
        children: 'У момент оформлення кожного окремого кредиту, перед отриманням коштів вам буде запропоновано ознайомитися з Договором позики із зазначенням параметрів конкретної операції та Правилами договору позики (Правила). Також чинний договір позики, після оформлення кредиту буде надіслано вам на e-mail, і протягом усього строку кредиту, договір позики буде доступний у вашому кабінеті.'
    },
    {
        title: 'Що таке електронний підпис одноразовим ідентифікатором?',
        children: 'Згідно зі ст. 12 ЗУ "Про електронну комерцію" - це алфавітно-цифрова послідовність символів, що ідентифікує вас у системі. Прикладом одноразового ідентифікатора може бути код, який надсилається користувачеві за допомогою sms або e-mail-повідомлення, під час реєстрації, що надалі використовується для входу в систему.'
    },
    {
        title: 'На який термін можна отримати кредит?',
        children: 'Термін кредитування залежно від кредиту становить від 7 днів до 120 днів.'
    },
    {
        title: 'Як погасити кредит?',
        children: <div>
            Перерахуванням коштів з банківської картки: у разі наявності коштів на будь-якій банківській картці (не обов'язково тій, на яку було оформлено кредит). Також ви можете погасити заборгованість в особистому кабінеті на сайті. Або ви можете погасити з банківської картки, використовуючи систему інтернет-банкінгу вашого банку, вказавши в параметрах одержувача платежу дані з договору.
            <br />
            <br />
            Погашенням готівкою: через будь-який платіжний термінал систем Citi24 (термінали в магазинах АТБ), вказавши як ідентифікатор номер вашого мобільного телефону, що зареєстрований у системі Groshi 24/7.
            <br />
            <br />
            Також, ви можете погасити готівкою через будь-який банк, вказавши в реквізитах одержувача дані з договору.
            <br />
            <br />
            Суму до погашення ви завжди можете перевірити у своєму кабінеті.
        </div>
    },
    {
        title: 'Чи можу я достроково погасити кредит?',
        children: 'Так, ви можете погасити кредит у будь-який день без штрафних санкцій за дострокове погашення. Перед здійсненням платежу перевірте суму до сплати у вашому особистому кабінеті.'
    },
    {
        title: 'Що буде якщо я не сплачу кредит вчасно?',
        children: <div>
            У разі неможливості вчасно сплатити кредитні зобов'язання, вам слід негайно звернутися в кол-центр Гроші 24/7  для вирішення питання реструктуризації.
            <br />
            <br />
            Згідно з кредитним договором, у разі прострочення платежу більш ніж на 5 днів, відсоткова ставка за користування кредитом перераховується в більшу сторону.
            <br />
            <br />
            У разі прострочення платежу більше ніж 10 днів, інформація буде передана до бюро кредитних історій, що значно ускладнить отримання вами кредитів у майбутньому в будь-яких інших кредитних установах.
            <br />
            <br />
            У разі прострочення платежу більш ніж на 30 днів, організація залишає за собою право звернутися за стягненням до суду, або передати кредит до колекторської компанії.
        </div>
    },
    {
        title: 'Чи можна продовжити термін кредиту / пролонгація кредиту?',
        children: 'Так, пролонгація кредиту можлива. Функція стає доступною за 3 дні до терміну закінчення кредиту в особистому кабінеті користувача.'
    },
    {
        title: 'Чи є можливість пролонгувати термін користування кредитом?',
        children: 'Якщо у вас виникли труднощі з погашенням у строк кредиту та сплатою нарахованих відсотків, ви можете оформити пролонгацію кредиту. Функція пролонгації кредиту стає доступною в Особистому кабінеті за три дні до закінчення початкового терміну кредитування. Пролонгація оформляється за допомогою додаткової угоди до договору шляхом його підписання індивідуальним алфавітно-цифровим ідентифікатором. Оформити пролонгацію кредиту можна на будь-який термін до 30 днів. Для того, щоб пролонгація почала діяти, необхідно сплатити відсотки, нараховані за користування кредитом до моменту пролонгації. Пролонгувати кредит можна необмежену кількість разів. Пролонгація змінює тільки строк користування кредитом, але не процентну ставку. Під час пролонгації діє та сама відсоткова ставка, що й у момент початкового строку кредитування.'
    },
    {
        title: 'Коли можна оформити заявку на отримання повторного кредиту?',
        children: 'Через 5-10 хв., після погашення чинного кредиту.'
    },
    {
        title: 'Що таке особистий кабінет і навіщо він потрібен?',
        children: 'Особистий кабінет - це ваша персональна сторінка на сайті Groshi 24/7. В особистому кабінеті ви можете змінити свої персональні дані, оформити заявку, погасити кредит, отримати знижку на наступний кредит. Також там відображається актуальна інформація про поточний кредит, бонуси і всі попередньо оформлені кредити.'
    },
    {
        title: 'Не можу увійти в особистий кабінет, не підходить пароль.',
        children: 'Якщо ви забули пароль - відновіть пароль, натиснувши "Забули пароль" і слідуйте інструкціям.'
    },
    {
        title: 'Чому мені відмовлено в отриманні кредиту?',
        children: 'Найпоширеніші причини відмови: вам менше 18 або більше 60 років, у вас немає постійного доходу, ви вказуєте неправдиву інформацію про себе. Також вам може бути відмовлено, якщо у вас були значні прострочення за кредитами в минулому.'
    },
    {
        title: 'Якщо я отримав відмову, чи можу звертатися повторно?',
        children: 'Якщо ви вказали неправдиві дані, ви можете ще 2 рази спробувати оформити заявку повторно відразу після отримання відмови. У разі, навмисного зазначення неправдивих даних, системою буде заблокована можливість отримання вами кредиту на термін 90 днів.'
    },
    {
        title: 'Що таке бонуси?',
        children: 'Бонуси - це умовні одиниці, які зараховуються учаснику бонусної програми на бонусний рахунок і списуються з нього, на умовах, передбачених Правилами програми лояльності "BonusUP". Еквівалент бонусу: 1 бонус=1 гривні.'
    },
    {
        title: 'Які бувають бонуси?',
        children: <div>
            Бонуси можуть бути планові та фактичні.
            <br />
            Плановий бонус відображається під час оформлення кредиту, отримання позитивного рішення і до моменту погашення кредиту. Плановий бонус не доступний до обміну.
            <br />
            Фактичний бонус - після повного погашення кредиту, плановий бонус переходить у статус фактичного, нараховується згідно з правилами нарахування залежно від суми фактичних витрат за кредитом. Наступного дня після погашення кредиту, фактичний бонус доступний до обміну.
        </div>
    },
    {
        title: 'Що таке бонусний рахунок?',
        children: 'Бонусний рахунок - це спеціальний рахунок клієнта, на якому зберігаються бонуси. Стан бонусного рахунку відображається в програмі "Мій кабінет" у розділі "Мої бонуси".'
    },
    {
        title: 'Які умови надання кредиту на навчання на IT ,професійних,мовних курсах?',
        children: <div>
            Наша фінансова компанія пропонує кредитування для IT курсів на наступних умовах:
            <ul className="ul">
                <li>Сума кредиту: від 10 000 грн до 50 000 грн.</li>
                <li>Строк кредитування: від 1 до 6 місяців.</li>
                <li>Валюта кредиту: гривня (₴).</li>
                <li>Оформлення кредиту: безкоштовне.</li>
                <li>Погашення: рівними щомісячними платежами.</li>
                <li>Потрібен получитель - це можуть бути батьки,рідні ,друзі</li>
                <li>Перерахування коштів: на рахунок чи картку в українському банку.</li>
            </ul>
            Ми надаємо можливість швидко та без зайвих витрат оформити кредит для вашого навчання.
        </div>
    },
    {
        title: 'Який термін кредитування доступний і чи можу я обрати період від 1 до 6 місяців?',
        children: 'Термін кредитування залежить від тривалості вашого навчання. Ви можете обрати період від 1 до 6 місяців, який відповідає строку ваших курсів. Під час подачі заявки на кредит вам необхідно додати рахунок-фактуру від IT-курсів або мовних курсів, де зазначено термін навчання'
    },
    {
        title: "Чи обов'язково потрібен поручитель для отримання кредиту?",
        children: 'Зазвичай ми надаємо цей тип кредиту з поручителем, оскільки це загальноприйнята практика в усьому світі. Багато людей, які звертаються за таким кредитом, тільки починають свій шлях у новій професії і ще не мають стабільного доходу. Однак, якщо у заявника хороша кредитна історія, він може подати заявку без поручителя.'
    },
    {
        title: 'Як здійснюється погашення кредиту і яка сума щомісячних платежів?',
        children: <div>
            Погашення кредиту здійснюється щомісяця рівними платежами. Коли ви введете свої дані в кредитний калькулятор, ви можете натиснути кнопку "<b>Показати графік платежів</b>". Графік платежів покаже дати та суми ваших майбутніх платежів.
        </div>
    },
    {
        title: 'Які документи необхідні для оформлення студентського кредиту?',
        children: <div>
            Для оформлення студентського кредиту вам необхідно надати такі документи:
            <ul className="ul">
                <li>Заяву на отримання кредиту, яку ви можете заповнити на нашому вебсайті.</li>
                <li>Рахунок-фактуру від курсів або коледжу, де зазначено вартість і тривалість навчання.</li>
                <li>Інформацію про поручителя</li>
            </ul>
            Надання цих документів дозволить нам швидко розглянути вашу заявку та запропонувати найкращі умови кредитування.
        </div>
    },
    {
        title: 'Чи можу я погасити кредит достроково без штрафних санкцій?',
        children: "Так, ви можете погасити кредит достроково без штрафних санкцій. Ми пропонуємо гнучкі умови для студентських кредитів, що дозволяють вам погасити позику без додаткових платежів чи штрафів. Це дає вам можливість зменшити загальну вартість кредиту та швидше завершити фінансові зобов'язання."
    },
    {
        title: "Яка максимальна сума кредиту доступна для сім'ї?",
        children: <div>
            Максимальна сума кредиту, доступна для сім'ї, залежить від обраного типу кредиту. Ми пропонуємо два види сімейних кредитів:
            <ol className="ol">
                <li><b>Короткостроковий кредит</b> — сума від <b>10000</b> до <b>50,000</b> грн на термін від 1 до 6 місяців. Цей кредит допоможе вашій сім'ї вирішити поточні питання ліквідності.</li>
                <li><b>Довгостроковий кредит</b> — значно більша сума, до <b>100000</b> призначена для вирішення стратегічних потреб сім'ї, таких як покупка меблів або незалежних енергоресурсів. Максимальна сума цього кредиту визначається індивідуально, залежно від ваших потреб та фінансових можливостей.</li>
            </ol>
            Таким чином, сума кредиту може варіюватися від <b>10,000 грн і більше</b>, залежно від обраного вами кредитного продукту та фінансової ситуації вашої сім'ї.
        </div>
    },
    {
        title: 'Як здійснюється погашення кредиту і яка сума щомісячних платежів?',
        children: <div>Погашення кредиту здійснюється щомісяця рівними платежами. Коли ви введете свої дані в кредитний калькулятор, ви можете натиснути кнопку "<b>Показати графік платежів</b>". Графік платежів покаже дати та суми ваших майбутніх платежів.</div>
    },
    {
        title: 'Скільки часу займає процес затвердження кредиту?',
        children: <div>
            Процес затвердження сімейного кредиту займає кілька етапів:
            <ol className="ol">
                <li><b>Подання заявки</b>: Спочатку вам потрібно заповнити заявку на кредит. Це можна зробити онлайн на нашому веб-сайті.</li>
                <li><b>Відеозустріч з кредитним менеджером</b>: Після відправлення заявки ви отримаєте запрошення у ваш особистий кабінет на відеодзвінок з кредитним менеджером для обговорення вашого запиту.</li>
                <li><b>Прийняття рішення</b>: Остаточне рішення щодо вашої заявки буде готове протягом <b>двох днів</b>: після відеозустрічі.</li>
                <li><b>Отримання коштів</b>: Після підписання кредитного договору гроші будуть негайно відправлені на вашу картку.</li>
                <li><b>Довгостроковий кредит</b> — значно більша сума, до <b>100000</b> призначена для вирішення стратегічних потреб сім'ї, таких як покупка меблів або незалежних енергоресурсів. Максимальна сума цього кредиту визначається індивідуально, залежно від ваших потреб та фінансових можливостей.</li>
            </ol>
            <b>Загальний час процесу затвердження кредиту</b> — від подання заявки до отримання коштів — займає приблизно <b>два-три дні</b>.
        </div>
    },
    {
        title: 'Які документи необхідні для оформлення кредиту?',
        children: <div>
            Для оформлення сімейного кредиту необхідно надати такі документ( прикріпити до онлайн аплікаціі на кредит )
            <ol className="ol">
                <li><b>Заповнена кредитна заявка</b> з вказанням всіх членів сім'ї, які виступають співпозичальниками за кредитом.</li>
                <li><b>Паспорти та ідентифікаційні коди</b> всіх співпозичальників.</li>
                <li><b>Документи, що підтверджують родинні зв'язки</b> між співпозичальниками (свідоцтво про шлюб, свідоцтва про народження тощо).</li>
                <li><b>Підтвердження доходів</b> кожного співпозичальника (довідка про доходи, виписка з банківського рахунку тощо).</li>
                <li><b>Вказання мети кредиту</b>: вам потрібно зазначити, на що саме плануєте використати кошти (наприклад, покупка меблів або ремонт).</li>
                <li><b>Підписаний кредитний договір</b> усіма членами сім'ї після затвердження заявки.</li>
            </ol>
            Оскільки це солідарний тип кредиту, всі члени сім'ї несуть відповідальність і підписують кредитний договір. Надання повної та точної інформації прискорить процес розгляду вашої заявки.
            <br/>
            <br/>
            У сімейному кредиті, де всі співпозичальники є членами сім'ї, <b>кожен співпозичальник несе повну відповідальність за погашення кредиту</b>. Це означає, що всі підписанти кредитного договору спільно відповідають за виконання фінансових зобов'язань перед кредитором.
        </div>
    },
    {
        title: "Які обов'язки та відповідальність кожного співпозичальника?",
        children: <div>
            Обов'язки та відповідальність кожного співпозичальника:
            <ol className="ol">
                <li><b>Солідарна відповідальність</b>: Кожен співпозичальник зобов'язаний забезпечити повне та своєчасне погашення кредиту, незалежно від особистого внеску або використання коштів.</li>
                <li><b>Виконання умов кредитного договору</b>: Усі співпозичальники повинні дотримуватися умов, прописаних у кредитному договорі, включаючи графік платежів та інші зобов'язання.</li>
                <li><b>Участь у підписанні договору</b>: Кожен член сім'ї, який є співпозичальником, повинен підписати кредитний договір, підтверджуючи свою згоду з умовами та відповідальність за кредит.</li>
                <li><b>Взаємна підтримка</b>: Співпозичальники повинні співпрацювати між собою для забезпечення своєчасних платежів та вирішення будь-яких фінансових питань, пов'язаних з кредитом.</li>
                <li><b>Інформування кредитора</b>: У разі виникнення обставин, що можуть вплинути на здатність погашати кредит (наприклад, втрата роботи одним із співпозичальників), необхідно негайно повідомити про це кредитора.</li>
            </ol>
            <b>Важливо розуміти</b>, що у випадку невиконання зобов'язань одним із співпозичальників, кредитор має право вимагати погашення всієї суми кредиту від інших співпозичальників. Тому відповідальність за кредит розподіляється між усіма членами сім'ї, і кожен з них повинен усвідомлювати свої обов'язки перед кредитором.
        </div>
    },
    {
        title: 'Не знайшли відповідь на своє запитання - телефонуйте нам!'
    }
]

const FaqPage = () => {
    return (
        <div className="faq_page">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="faq" />
                    <div className="faq_page__wrapper">
                        <h1 className="title">Відповіді на поширені запитання</h1>
                        <div className="faq__list">
                            {data.map(({ title, children }, index) => (
                                <div className="faq__item" key={`faq-item-${index}`}>
                                    <div className="faq__header">{title}</div>
                                    {children && (
                                        <div className="faq__body">
                                            {children}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default FaqPage;