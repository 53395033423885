import React from "react";
import "./button.scss";

const Button = ({ children, pink, big, border, green, onClick, noBg, disabled= false, style = {} }) => {
    return (
        <button
            className={`btn ${pink ? 'btn__pink' : ''} ${big ? 'btn__big' : ''} ${border ? 'btn__border' : ''} ${green ? 'btn__green' : ''} ${noBg ? 'btn__no_bg' : ''}`}
            onClick={onClick}
            disabled={disabled}
            style={style}
        >
            {children}
        </button>
    );
};

export default Button;