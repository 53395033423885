import React from "react";
import Button from "../Common/Button";
import ScrollLink from "../../lib/helpers/ScrollLink";
import "./FAQ.scss";

const data = [
    {
        title: 'На яку суму можна отримати кредит на картку на сайті Groshi 24/7?',
        children: 'Ви можете отримати кредит онлайн на суму від 1 000,00 грн до 50 000,00 грн Термін кредитування може бути обраний у межах від 7 днів до 120 днів, в залежності від кредитного рейтинга позичальника.'
    },
    {
        title: 'Що потрібно для оформлення мікрозайму?',
        children: <div>
            Для отримання кредиту, зазвичай потрібно наступне:
            1. Комп'ютер або мобільний телефон з доступом до Інтернету
            2. Банківська картка будь-якого банку України, оформлена на ваше ім'я. Вона може використовуватися для отримання коштів або погашення кредиту.
            3. Паспорт для ідентифікації вас як заявника кредиту.
            4. Ідентифікаційний код - це унікальний ідентифікатор, присвоєний кожному громадянину України.
        </div>
    },
    {
        title: 'До якого віку дають споживчий кредит?',
        children: 'Вимоги до позичальників: громадянство України, вік від 18 до 60 років, відсутність значних прострочень у кредитній історії.'
    }
]

const FAQ = ({ backgroundColorWrapper = '#fcf0eb', list = data }) => {
    return (
        <section className="faq" style={{ backgroundColor: backgroundColorWrapper }}>
            <div className="container">
                <h2 className="title">Відповіді на поширені запитання</h2>
                <div className="faq__list">
                    {list.map(({ title, children }, index) => (
                        <div className="faq__item" key={`faq-item-${index}`}>
                            <div className="faq__header">{title}</div>
                            <div className="faq__body">
                                {children}
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '60px' }}>
                    <ScrollLink to='/uk/pitannya-ta-vidpovidi'>
                        <Button pink>Далі</Button>
                    </ScrollLink>
                </div>
            </div>
        </section>
    );
};

export default FAQ;