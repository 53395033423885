import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'
import { SlArrowDown } from 'react-icons/sl'

const AccordionCustom = ({
							 title,
							 children,
							 id = 'panel1-header',
							 ariaControls = 'panel1-content',
							 defaultExpanded = false
}) => {
	return (
		<Accordion defaultExpanded={defaultExpanded}>
			<AccordionSummary expandIcon={<SlArrowDown />} aria-controls={ariaControls} id={id} className="accordion_custom__header">
				{title}
			</AccordionSummary>
			<AccordionDetails className="accordion_custom__content">
				{children}
			</AccordionDetails>
		</Accordion>
	);
};

export default AccordionCustom;