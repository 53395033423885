import React, { useState } from 'react'

const PaydayLoanGiveCredit = () => {
	const [showTerm, setShowTerm] = useState(false)

	return (
		<div className="terms_using_credit">
			<div className="container">
				<div className="title">Бери кредит відповідально</div>
				<div className="terms_using_credit__wrapper">
					<div className={`terms_using_credit__info ${showTerm ? 'active' : ''}`}>
						Перед взяттям кредиту завжди слід оцінити свої потреби та можливості, а також ретельно обміркувати, чи доцільно брати кредит.
						<br/>
						<br/>
						Оцініть, чи відповідають кредитні відносини Вашому особистому інтересу до кредиту та фінансового стану. Обміркуйте можливі зміни у Вашому житті (збільшення витрат, наприклад, створення сім'ї або зниження доходів, наприклад, вихід на пенсію, зниження заробітної плати або втрата роботи) і як вони можуть вплинути на можливість повернення кредиту.
						<br/>
						<br/>
						Завжди надайте  всю достовірну та необхідну інформацію, що стосується Вашого фінансового становища, доходів, зобов'язань та інших даних, що запитуються в завці на кредит
						<br/>
						<br/>
						Уважно ознайомтеся з договором за споживчим кредитом. Ретельно обміркуйте, чи відповідають запропоновані умови Вашим потребам. Якщо є запитання Ви можете звернутись за роязсненнями в чат або по телефону <a href="tel:+380800207613">+380800207613</a>
						<br/>
						<br/>
						- Товариство з обмеженою відповідальністю «ГРОШІ 247» має право вносити зміни до укладених зі Споживачами договорів про споживчий кредит тільки за взаємною згодою сторін у спосіб та на умовах визначених договором про споживчий кредит;
						<br/>
						<br/>
						- Споживач має право відмовитися від отримання рекламних матеріалів Товариства засобами дистанційних каналів комунікації, шляхом надсилання відповідного повідомлення Товариству;
						<br/>
						<br/>
						- Можливі витрати на сплату Споживачем платежів за користування споживчим кредитом в залежності від обраного Споживачем способу сплати;
						<br/>
						<br/>
						- Споживач має право на продовження (пролонгації) строку погашення споживчого кредиту (строку виконання грошового зобов’язання)/строку кредитування/строку дії договору про споживчий кредит за згодою Товариства. Ці дії на стандартних умовах здійснюються в Особистому кабінеті Споживача, без змін або зі зміною умов попередньо укладеного договору в бік погіршення для Споживача із зазначенням переліку та цифрового значення умов, що підлягають зміні та залежить від виду договору про споживче кредитування. Для отримання індивідуальних умов продовження строку погашення споживчого кредиту, внесення інших змін в договір споживчого кредиту Позичальник повинен направити письмове звернення Товариству із зазначенням бажаних умов зміни умов кредитування. Товариство розглядає таке звернення відповідно до внутрішніх процедур Товариства і повідомляє клієнта про своє рішення.
						<br/>
						<br/>
						Споживач повинен негайно інформувати Товариство про несанкціонований доступ або зміну інформації про себе в системах дистанційного обслуговування Товариства з обмеженою відповідальністю «ГРОШІ 247».
						<br/>
						<br/>
						УМОВИ КОРИСТУВАННЯ КРЕДИТОМ ДУЖЕ ПРО
					</div>
					<div className="terms_using_credit__border" />
					<button className="btn btn__pink" onClick={() => setShowTerm(!showTerm)}>
						{showTerm ? 'Сховати' : 'Читати повністю'}
					</button>
				</div>
			</div>
		</div>
	)
}

export default PaydayLoanGiveCredit;