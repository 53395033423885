import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const BlackFriday = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">Підготуйтеся до шалених розпродажів</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Чорна п'ятниця вже не за горами, і в багатьох онлайн і офлайн магазинах вже стартували мега сейли. Не проґавте шанс придбати товари та послуги, про які давно мріяли, за максимально низькими цінами під час BLACK FRIDAY. Не варто відмовлятися від вигідних пропозицій, навіть якщо наразі коштів недостатньо.
                                <br />
                                <br />
                                Скористайтеся швидкою мікропозикою від Groshi 24/7, оформіть заявку онлайн <ScrollLink to="/">тут</ScrollLink> і отримайте гроші на картку вже через декілька хвилин!
                                <br />
                                <br />
                                А якщо ви візьмете кредит з 18.11.2024 р. до 29.11.2024 р. включно та погасите його до кінця року (31.12.2024 р.), на вас чекає приємний бонус - знижка -50% на наступний кредит.
                                <br />
                                <br />
                                Встигніть поповнити рахунки до Чорної п'ятниці та насолоджуйтеся вигідним шопінгом!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default BlackFriday;