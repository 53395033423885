import React from 'react'

import Footer from '../components/Footer/Footer'
import FAQ from '../components/FAQ/FAQ'
import ApplicationForm from './../components/Forms/ApplicationForm'
import 'swiper/css'
import FamilyLoanWhyProfitableTake from './components/FamilyLoan/FamilyLoanWhyProfitableTake'
import FamilyLoanHowGet from './components/FamilyLoan/FamilyLoanHowGet'
import FamilyLoanCalculate from './components/FamilyLoan/FamilyLoanCalculate'
import FamilyLoanConditions from './components/FamilyLoan/FamilyLoanConditions'
import FamilyLoanSimpleSteps from './components/FamilyLoan/FamilyLoanSimpleSteps'
import Reviews from '../components/Reviews/Reviews'

const FamilyLoan = () => {
    return (
        <div className="payday_loan">
            <FamilyLoanCalculate />

            <FamilyLoanWhyProfitableTake />

            {/* <div className="get_loan payday_loan_from_us">
                <div className="container">
                    <div className="get_loan__list">
                        <div className="get_loan__item">
                            <img src={fastestDeliveryIcon} alt="icon 1" />
                            <div className="get_loan__item_text">Подай  заявку на позику, і  Гроші 24/7 будуть на твоій картці вже через 5 хвилин.</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={phoneIcon} alt="icon 2" />
                            <div className="get_loan__item_text">100%<br /> on-line</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={timeIsMoneyIcon} alt="icon 3" />
                            <div className="get_loan__item_text">Кредит можна<br /> пролонгувати</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={percentIcon} alt="icon 4" />
                            <div className="get_loan__item_text">Діє програма<br /> лояльності</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={shieldIcon} alt="icon 5" />
                            <div className="get_loan__item_text">Завдяки автоматизованій<br /> обробці та<br /> персоналізованому підходу.</div>
                        </div>
                    </div>
                </div>
            </div> */}

            <ApplicationForm />

            <FamilyLoanHowGet />

            <FamilyLoanConditions />

            <FamilyLoanSimpleSteps />

            <Reviews
                list={[
                    { sender: 'Oксана', text: 'У нашій сім’ї з’явилася друга дитина, і ми вирішили зробити ремонт у будинку, щоб створити старшій дитині її особистий простір. Проте будівельні матеріали зараз дуже дорогі, і нам була потрібна фінансова допомога. Ми звернулися до цього сервісу, і вони допомогли нам отримати кредит на ремонт. Усе пройшло швидко та без зайвих складнощів. Тепер у нашій оселі стало більше затишку, і кожен має свій куточок. Ми дуже вдячні за вашу допомогу! Вся сім’я щаслива, дякуємо цьому сервісу за підтримку у важливий момент нашого життя', isShowBtn: true },
                    { sender: 'Віталій', text: 'Іноді трапляється неприємність – дорожньо-транспортна пригода. На жаль, це сталося і зі мною. Це був великий удар, адже я виявився винним і змушений був відремонтувати не лише свою машину, але й чужу. Це була значна сума для мене, яка стала серйозним випробуванням для сімейного бюджету. На щастя, я звернувся до цього сервісу, і вони допомогли мені отримати кредит. Завдяки їхній підтримці, протягом 4 місяців я впорався з усіма витратами та зміг забезпечити стабільність у сім\'ї. Щиро вдячний за допомогу у такий важкий час', isShowBtn: true },
                    { sender: 'Лукьян', text: 'Ви знаєте, як важко сьогодні зі світлом. Моя дружина і я працюємо віддалено, часто з клієнтами з різних країн, тому нам потрібно завжди залишатися на зв\'язку. Щоб забезпечити незалежність від перебоїв зі світлом, я вирішив придбати генератор для нашої родини. Це було нелегке рішення, адже генератор коштує чимало. Переді мною став вибір – залишитися без світла і без роботи або оплатити свою незалежність. Я вирішив платити і звернувся до цього сервісу. Підготував усі необхідні документи, і мені швидко виділили кредит. Тепер я впевнений, що зможу вчасно виконувати свою роботу, незалежно від обставин. Дякую сервісу за допомогу і щиро раджу всім, хто опинився у подібній ситуації, звертатися до них', isShowBtn: true }
                ]}
            />

            <FAQ
                list={[
                    {
                        title: "Яка максимальна сума кредиту доступна для сім'ї?",
                        children: <div>
                            Максимальна сума кредиту, доступна для сім'ї, залежить від обраного типу кредиту. Ми пропонуємо два види сімейних кредитів:
                            <ol className="ol">
                                <li><b>Короткостроковий кредит</b> — сума від <b>10000</b> до <b>50,000</b> грн на термін від 1 до 6 місяців. Цей кредит допоможе вашій сім'ї вирішити поточні питання ліквідності.</li>
                                <li><b>Довгостроковий кредит</b> — значно більша сума, до <b>100000</b> призначена для вирішення стратегічних потреб сім'ї, таких як покупка меблів або незалежних енергоресурсів. Максимальна сума цього кредиту визначається індивідуально, залежно від ваших потреб та фінансових можливостей.</li>
                            </ol>
                            Таким чином, сума кредиту може варіюватися від <b>10,000 грн і більше</b>, залежно від обраного вами кредитного продукту та фінансової ситуації вашої сім'ї.
                        </div>
                    },
                    {
                        title: 'Як здійснюється погашення кредиту і яка сума щомісячних платежів?',
                        children: <div>Погашення кредиту здійснюється щомісяця рівними платежами. Коли ви введете свої дані в кредитний калькулятор, ви можете натиснути кнопку "<b>Показати графік платежів</b>". Графік платежів покаже дати та суми ваших майбутніх платежів.</div>
                    },
                    {
                        title: 'Скільки часу займає процес затвердження кредиту?',
                        children: <div>
                            Процес затвердження сімейного кредиту займає кілька етапів:
                            <ol className="ol">
                                <li><b>Подання заявки</b>: Спочатку вам потрібно заповнити заявку на кредит. Це можна зробити онлайн на нашому веб-сайті.</li>
                                <li><b>Відеозустріч з кредитним менеджером</b>: Після відправлення заявки ви отримаєте запрошення у ваш особистий кабінет на відеодзвінок з кредитним менеджером для обговорення вашого запиту.</li>
                                <li><b>Прийняття рішення</b>: Остаточне рішення щодо вашої заявки буде готове протягом <b>двох днів</b>: після відеозустрічі.</li>
                                <li><b>Отримання коштів</b>: Після підписання кредитного договору гроші будуть негайно відправлені на вашу картку.</li>
                                <li><b>Довгостроковий кредит</b> — значно більша сума, до <b>100000</b> призначена для вирішення стратегічних потреб сім'ї, таких як покупка меблів або незалежних енергоресурсів. Максимальна сума цього кредиту визначається індивідуально, залежно від ваших потреб та фінансових можливостей.</li>
                            </ol>
                            <b>Загальний час процесу затвердження кредиту</b> — від подання заявки до отримання коштів — займає приблизно <b>два-три дні</b>.
                        </div>
                    },
                    {
                        title: 'Які документи необхідні для оформлення кредиту?',
                        children: <div>
                            Для оформлення сімейного кредиту необхідно надати такі документ( прикріпити до онлайн аплікаціі на кредит )
                            <ol className="ol">
                                <li><b>Заповнена кредитна заявка</b> з вказанням всіх членів сім'ї, які виступають співпозичальниками за кредитом.</li>
                                <li><b>Паспорти та ідентифікаційні коди</b> всіх співпозичальників.</li>
                                <li><b>Документи, що підтверджують родинні зв'язки</b> між співпозичальниками (свідоцтво про шлюб, свідоцтва про народження тощо).</li>
                                <li><b>Підтвердження доходів</b> кожного співпозичальника (довідка про доходи, виписка з банківського рахунку тощо).</li>
                                <li><b>Вказання мети кредиту</b>: вам потрібно зазначити, на що саме плануєте використати кошти (наприклад, покупка меблів або ремонт).</li>
                                <li><b>Підписаний кредитний договір</b> усіма членами сім'ї після затвердження заявки.</li>
                            </ol>
                            Оскільки це солідарний тип кредиту, всі члени сім'ї несуть відповідальність і підписують кредитний договір. Надання повної та точної інформації прискорить процес розгляду вашої заявки.
                            <br/>
                            <br/>
                            У сімейному кредиті, де всі співпозичальники є членами сім'ї, <b>кожен співпозичальник несе повну відповідальність за погашення кредиту</b>. Це означає, що всі підписанти кредитного договору спільно відповідають за виконання фінансових зобов'язань перед кредитором.
                        </div>
                    },
                    {
                        title: "Які обов'язки та відповідальність кожного співпозичальника?",
                        children: <div>
                            Обов'язки та відповідальність кожного співпозичальника:
                            <ol className="ol">
                                <li><b>Солідарна відповідальність</b>: Кожен співпозичальник зобов'язаний забезпечити повне та своєчасне погашення кредиту, незалежно від особистого внеску або використання коштів.</li>
                                <li><b>Виконання умов кредитного договору</b>: Усі співпозичальники повинні дотримуватися умов, прописаних у кредитному договорі, включаючи графік платежів та інші зобов'язання.</li>
                                <li><b>Участь у підписанні договору</b>: Кожен член сім'ї, який є співпозичальником, повинен підписати кредитний договір, підтверджуючи свою згоду з умовами та відповідальність за кредит.</li>
                                <li><b>Взаємна підтримка</b>: Співпозичальники повинні співпрацювати між собою для забезпечення своєчасних платежів та вирішення будь-яких фінансових питань, пов'язаних з кредитом.</li>
                                <li><b>Інформування кредитора</b>: У разі виникнення обставин, що можуть вплинути на здатність погашати кредит (наприклад, втрата роботи одним із співпозичальників), необхідно негайно повідомити про це кредитора.</li>
                            </ol>
                            <b>Важливо розуміти</b>, що у випадку невиконання зобов'язань одним із співпозичальників, кредитор має право вимагати погашення всієї суми кредиту від інших співпозичальників. Тому відповідальність за кредит розподіляється між усіма членами сім'ї, і кожен з них повинен усвідомлювати свої обов'язки перед кредитором.
                        </div>
                    }
                ]}
            />

            <Footer />
        </div>
    );
};

export default FamilyLoan;