import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const MoneyAutumnContinues = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">Кредит з вигодою -50%!</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Акція від Groshi 24/7 "ГРОШОВА ОСІНЬ" триває. Оформлюючи кредит на суму від 3000 грн до 6000 грн, ви маєте можливість отримати знижку -50%.
                                <br />
                                <br />
                                Поспішіть підготуватися до холодів, позичивши необхідну суму на найвигідніших умовах! Щоб отримати кредитні кошти за дисконтною процентною ставкою, переходьте за посиланням <ScrollLink to="/">https://groshi247.com/</ScrollLink>, реєструйтеся та подавайте заявку.
                                <br />
                                <br />
                                ____
                                <br />
                                <br />
                                Акція діє до 30.11.2024 р. на всій підконтрольній Україні території, окрім тимчасово окупованих та оточених регіонів, а також районів, де ведуться бойові дії.
                                <br />
                                <br />
                                Інформація про істотні характеристики послуги з надання мікрокредитів <a href="https://cutt.ly/RebIfU0W" target="_blank">https://cutt.ly/RebIfU0W</a> та Попередження про невиконання умов договору кредитування <a href="https://cutt.ly/henryljy" target="_blank">https://cutt.ly/henryljy</a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default MoneyAutumnContinues;