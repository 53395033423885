import React from 'react'

import Footer from '../components/Footer/Footer'
import FAQ from '../components/FAQ/FAQ'
import StudyLoanWhyProfitableTakeLoan from './components/StudyLoan/StudyLoanWhyProfitableTakeLoan'
import StudyLoanToGetLoan from './components/StudyLoan/StudyLoanToGetLoan'
import StudyLoanCalculate from './components/StudyLoan/StudyLoanCalculate'
import ApplicationForm from '../components/Forms/ApplicationForm'
import StudyLoanLeaveRequest from './components/StudyLoan/StudyLoanLeaveRequest'
import 'swiper/css'
import Reviews from '../components/Reviews/Reviews'
import StudyLoanSimpleSteps from "./components/StudyLoan/StudyLoanSimpleSteps";

const StudyLoan = () => {
    return (
        <div className="study_loan">
            {/* Calculator */}
            <StudyLoanCalculate />

            {/* Why is it profitable to take a loan? */}
            <StudyLoanWhyProfitableTakeLoan />

            {/* Application form */}
            <ApplicationForm />

            {/* To get a loan */}
            <StudyLoanToGetLoan />

            <StudyLoanSimpleSteps />

            <StudyLoanLeaveRequest />

            <Reviews
                list={[
                    { sender: 'Олексій', text: 'Я вирішив змінити професію та вступити на довготривалі курси з IT, але зіткнувся з фінансовими труднощами. Банк відмовив у наданні кредиту, і я вже не знав, що робити. На щастя, в інтернеті я натрапив на цей сервіс Groshi 247  і вирішив спробувати. Усе виявилося дуже просто – заявку на кредит для студентів я оформив онлайн, а кошти на картку отримав у найкоротший термін. Умови прозорі, відсотки доступні, а графік погашення зручний. Завдяки цьому сервісу я зміг оплатити курси та тепер маю шанс реалізувати свої мрії в новій професії. Дуже вдячний і рекомендую всім, хто опинився у схожій ситуації', isShowBtn: true },
                    { sender: 'Інна', text: 'Хочу поділитися позитивним досвідом! Вирішила вивчати англійську для кар\'єрного росту, але курси виявилися досить дорогими. Завдяки сервісу Groshi 247 змогла отримати кредит без зайвих затримок. Усе прозоро, швидко і дуже зручно. Курси вже оплачено, і тепер я рухаюся до своїх цілей. Велике дякую за допомогу', isShowBtn: false },
                    { sender: 'Андрій', text: 'Я переїхав із сходу країни та вирішив змінити професію – хочу стати вебдизайнером. На початку навчання мав гроші лише на перший семестр, сподівався зібрати необхідну суму з допомогою друзів, але не вдалося. Ситуація стала критичною – без оплати я б не зміг продовжити навчання. На щастя, я знайшов цей сервіс Groshi 247 в інтернеті. Після розмови з менеджером вони запропонували варіант із додаванням поручителя, і один із моїх друзів погодився допомогти. Завдяки цьому я отримав необхідні кошти та зміг продовжити навчання. Дуже вдячний за таку можливість! Сподіваюся завершити навчання та знайти хорошу роботу. Дякую вам за підтримку у складний момент', isShowBtn: true },
                    { sender: 'Лера', text: 'Я завжди любила собачок і мріяла про роботу, пов’язану з доглядом за домашніми улюбленцями. Але мені бракувало знань та навичок для цього. Зараз я працюю касиром у супермаркеті, але дуже хотіла змінити своє життя. Знайшла чудову школу, де навчають догляду за домашніми тваринами, але потрібні були гроші, щоб оплатити навчання. Моя сестра знайшла цей сервіс в інтернеті, і завдяки їм ми отримали кредит на мій курс. Сестра стала поручителем, і все пройшло дуже легко та швидко. Тепер я щаслива, що зможу реалізувати свою мрію та працювати улюблену справу завдяки цій допомозі. Дякую від щирого серця', isShowBtn: true }
                ]}
            />

            <FAQ
                list={[
                    {
                        title: 'Які умови надання кредиту на навчання на IT ,професійних,мовних курсах?',
                        children: <div>
                            Наша фінансова компанія пропонує кредитування для IT курсів на наступних умовах:
                            <ul className="ul">
                                <li>Сума кредиту: від 10 000 грн до 50 000 грн.</li>
                                <li>Строк кредитування: від 1 до 6 місяців.</li>
                                <li>Валюта кредиту: гривня (₴).</li>
                                <li>Оформлення кредиту: безкоштовне.</li>
                                <li>Погашення: рівними щомісячними платежами.</li>
                                <li>Потрібен получитель - це можуть бути батьки,рідні ,друзі</li>
                                <li>Перерахування коштів: на рахунок чи картку в українському банку.</li>
                            </ul>
                            Ми надаємо можливість швидко та без зайвих витрат оформити кредит для вашого навчання.
                        </div>
                    },
                    {
                        title: 'Який термін кредитування доступний і чи можу я обрати період від 1 до 6 місяців?',
                        children: 'Термін кредитування залежить від тривалості вашого навчання. Ви можете обрати період від 1 до 6 місяців, який відповідає строку ваших курсів. Під час подачі заявки на кредит вам необхідно додати рахунок-фактуру від IT-курсів або мовних курсів, де зазначено термін навчання'
                    },
                    {
                        title: "Чи обов'язково потрібен поручитель для отримання кредиту?",
                        children: 'Зазвичай ми надаємо цей тип кредиту з поручителем, оскільки це загальноприйнята практика в усьому світі. Багато людей, які звертаються за таким кредитом, тільки починають свій шлях у новій професії і ще не мають стабільного доходу. Однак, якщо у заявника хороша кредитна історія, він може подати заявку без поручителя.'
                    },
                    {
                        title: 'Як здійснюється погашення кредиту і яка сума щомісячних платежів?',
                        children: <div>
                            Погашення кредиту здійснюється щомісяця рівними платежами. Коли ви введете свої дані в кредитний калькулятор, ви можете натиснути кнопку "<b>Показати графік платежів</b>". Графік платежів покаже дати та суми ваших майбутніх платежів.
                        </div>
                    },
                    {
                        title: 'Які документи необхідні для оформлення студентського кредиту?',
                        children: <div>
                            Для оформлення студентського кредиту вам необхідно надати такі документи:
                            <ul className="ul">
                                <li>Заяву на отримання кредиту, яку ви можете заповнити на нашому вебсайті.</li>
                                <li>Рахунок-фактуру від курсів або коледжу, де зазначено вартість і тривалість навчання.</li>
                                <li>Інформацію про поручителя</li>
                            </ul>
                            Надання цих документів дозволить нам швидко розглянути вашу заявку та запропонувати найкращі умови кредитування.
                        </div>
                    },
                    {
                        title: 'Чи можу я погасити кредит достроково без штрафних санкцій?',
                        children: "Так, ви можете погасити кредит достроково без штрафних санкцій. Ми пропонуємо гнучкі умови для студентських кредитів, що дозволяють вам погасити позику без додаткових платежів чи штрафів. Це дає вам можливість зменшити загальну вартість кредиту та швидше завершити фінансові зобов'язання."
                    }
                ]}
            />

            <Footer />
        </div>
    );
};

export default StudyLoan;