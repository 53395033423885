import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import TermsUsingCredit from '../components/TermsUsingCredit/TermsUsingCredit'
import ScrollLink from '../lib/helpers/ScrollLink'
import video1 from '../assets/video/1.mp4'
import moneyAutumnGroshi247 from '../assets/video/money_autumn_groshi_247.mp4'
import blackFriday201124 from '../assets/video/black_friday_20_11.mp4'
import moneyBeforeSalary from '../assets/stock/money_before_salary.png'
import simpleSteps from '../assets/stock/4_simple_steps.png'
import documentRequired from '../assets/stock/documnet_required.png'
import moneyAutumnContinues from '../assets/stock/money_autumn_continues.png'
import redDarkCinema from '../assets/stock/red_dark_cinema.png'
import teacherSchool from '../assets/stock/teacher_school.png'
import stockInterestRate from '../assets/stock/stock_interest_rate.png'

const Stock = () => {
    return (
        <div className="stock">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="stock" />
                    <div className="blog__wrapper">
                        <div className="title">Акції</div>
                        <div className="blog__list">
                            <div className="blog__item">
                                <div className="blog__video">
                                    <video controls loop autoPlay muted>
                                        <source src={blackFriday201124} type="video/mp4" />
                                    </video>
                                </div>
                                <div className="blog__title">
                                    -50% на наступний кредит
                                </div>
                                <div className="blog__desc">
                                    Підготуйтеся до шалених розпродажів
                                </div>
                                <ScrollLink to="/promoactionnews/black-friday" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={stockInterestRate} alt="Stock Interest Rate" />
                                </div>
                                <div className="blog__title">
                                    Процентна ставка 0,5%
                                </div>
                                <div className="blog__desc">
                                    Кредити зі знижкою - 50% до кінця осені
                                </div>
                                <ScrollLink to="/promoactionnews/interest-rate" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={teacherSchool} alt="Teacher School" />
                                </div>
                                <div className="blog__title">
                                    Здайте гроші на...
                                </div>
                                <div className="blog__desc">
                                    Витрати, яким ні кінця, ні краю!
                                </div>
                                <ScrollLink to="/promoactionnews/teacher-school" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={moneyAutumnContinues} alt="Money Before Salary" />
                                </div>
                                <div className="blog__title">
                                    Кредит з вигодою -50%
                                </div>
                                <div className="blog__desc">
                                    Акція "ГРОШОВА ОСІНЬ" триває
                                </div>
                                <ScrollLink to="/promoactionnews/money-autumn-continues" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={documentRequired} alt="documentRequired" />
                                </div>
                                <div className="blog__title">
                                    Які документи потрібні?
                                </div>
                                <div className="blog__desc">
                                    Тільки ПАСПОРТ та ІПН
                                </div>
                                <ScrollLink to="/promoactionnews/documents-are-required" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={redDarkCinema} alt="Red Dark Cinema" />
                                </div>
                                <div className="blog__title">
                                    Плануєте побачення?
                                </div>
                                <div className="blog__desc">
                                    Де взяти гроші на романтік?
                                </div>
                                <ScrollLink to="/promoactionnews/romance-cost" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={simpleSteps} alt="4 simple steps" />
                                </div>
                                <div className="blog__title">
                                    4 ПРОСТІ КРОКИ
                                </div>
                                <div className="blog__desc">
                                    Максимально просто й швидко
                                </div>
                                <ScrollLink to="/promoactionnews/4-simple-steps" className="btn btn__pink">
                                    Читати далі
                                </ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__video">
                                    <video controls loop autoPlay muted>
                                        <source src={moneyAutumnGroshi247} type="video/mp4" />
                                    </video>
                                </div>
                                <div className="blog__title">
                                    ГРОШОВА ОСІНЬ -50%
                                </div>
                                <div className="blog__desc">
                                    Акція -50% за промокодом N50
                                </div>
                                <ScrollLink to="/promoactionnews/money-autumn" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={moneyBeforeSalary} alt="Money Before Salary" />
                                </div>
                                <div className="blog__title">
                                    Наші переваги
                                </div>
                                <div className="blog__desc">
                                    Гроші онлайн – до зарплати не чекай!
                                </div>
                                <ScrollLink to="/promoactionnews/money-before-salary" className="btn btn__pink">
                                    Читати далі
                                </ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__video">
                                    <video controls loop autoPlay muted>
                                        <source src={video1} type="video/mp4" />
                                    </video>
                                </div>
                                <div className="blog__title">
                                    Потрібні гроші до 1 вересня?
                                </div>
                                <div className="blog__desc">
                                    Допоможемо зібрати дитину в школу
                                </div>
                                <ScrollLink to="/promoactionnews/need-money" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            {/* <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg4} alt="Blog img 4" />
                                </div>
                                <div className="blog__title">
                                    Знижено ставку
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg5} alt="Blog img 5" />
                                </div>
                                <div className="blog__title">
                                    Акція до Дня Незалежності
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg6} alt="Blog img 6" />
                                </div>
                                <div className="blog__title">
                                    Знижка 10%
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg1} alt="Blog img 1" />
                                </div>
                                <div className="blog__title">
                                    Знижено ставку
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg2} alt="Blog img 2" />
                                </div>
                                <div className="blog__title">
                                    Акція до Дня Незалежності
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img src={blogImg3} alt="Blog img 3" />
                                </div>
                                <div className="blog__title">
                                    Знижка 10%
                                </div>
                                <div className="blog__desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div> */}
                        </div>
                        {/* <div className="blog__btn">
                            <button className="btn btn__green">Більше статей</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default Stock;