import React, { useState } from 'react'
import Button from '../../../components/Common/Button'
import icons1 from '../../../assets/study_loan/type/groshi_24_7_icons-01.png'
import icons3 from '../../../assets/study_loan/type/groshi_24_7_icons-03.png'
import icons4 from '../../../assets/study_loan/type/groshi_24_7_icons-04.png'
import icons5 from '../../../assets/study_loan/type/groshi_24_7_icons-05.png'
import icons6 from '../../../assets/study_loan/type/groshi_24_7_icons-06.png'
import icons7 from '../../../assets/study_loan/type/groshi_24_7_icons-07.png'
import icons8 from '../../../assets/study_loan/type/groshi_24_7_icons-08.png'
import icons9 from '../../../assets/study_loan/type/groshi_24_7_icons-09.png'
import languageCourseIcon from '../../../assets/study_loan/type/language_course.png'
import {Controller, useForm} from "react-hook-form";
import {sendApplicationEmail} from "../../../lib/helpers/emailService";
import {FormControl, FormHelperText, TextField} from "@mui/material";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";

const list = [
	{ id: 1, text: 'Професійні курси', srcImg: icons1, altImg: '1 img' },
	{ id: 2, text: 'Мовні курси', srcImg: languageCourseIcon, altImg: '2 img' },
	{ id: 3, text: "Комп'ютерні курси", srcImg: icons3, altImg: '3 img' },
	{ id: 4, text: 'Програми MBA', srcImg: icons4, altImg: '4 img' },
	{ id: 5, text: 'Підготовчі курси', srcImg: icons5, altImg: '5 img' },
	{ id: 6, text: 'Вступ до коледжу', srcImg: icons6, altImg: '6 img' },
	{ id: 7, text: 'Вступ до вузу', srcImg: icons7, altImg: '7 img' },
	{ id: 8, text: 'Курси для розвитку дітей', srcImg: icons8, altImg: '8 img' },
	{ id: 9, text: 'Курси дизайну', srcImg: icons9, altImg: '9 img' }
]

const formInitialValues = {
	name: '',
	phone: '',
	email: ''
}

const LeaveRequestForm = ({ chooseFormId }) => {
	const [isFormSend, setIsFormSend] = useState(false)

	const {
		control,
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
		reset,

	} = useForm({
		mode: 'onTouched',
		defaultValues: formInitialValues
	});

	const onSubmit = async (data) => {
		const response = await sendApplicationEmail({...data, type: list.find(item => item.id === chooseFormId).text});
		if (response === "OK") {
			reset(formInitialValues)
			setIsFormSend(true)
		}
	}

	return (
		<form
			className="application_form"
			style={{ marginTop: '35px' }}
			onSubmit={handleSubmit(onSubmit)}
			onChange={() => setIsFormSend(false)}
		>
			<div className="title">{list.find(item => item.id === chooseFormId).text}. Заявка</div>
			<div>Найближчим часом в робочі години ми з Вами зв'яжемося, щоб розповісти докладніше про можливість навчання в кредит.</div>
			<FormControl fullWidth>
				<TextField
					size="small"
					variant="standard"
					placeholder="Прізвище, ім'я, по батькові"
					{...register('name', {
						required: 'Це поле обов\'язкове', validate: (value) => {
							// ФИО regExp
							const nameRegex = /^[А-ЯІЇЄҐ][а-яіїєґ]+\s[A-ЯІЇЄҐ][а-яіїєґ]+\s[A-ЯІЇЄҐ][а-яіїєґ]+$/;
							return nameRegex.test(value) || 'Введіть Фамілію Ім\'я По батькові у правильному форматі';
						}
					})}
					error={!!errors.name}
					helperText={errors.name?.message}
				/>
				{!errors.name?.message && <FormHelperText>
					Введіть точно так, як в паспорті
				</FormHelperText>}
			</FormControl>

			<FormControl sx={{ width: { xs: '100%', sm: '48%' } }}>
				<TextField
					size="small"
					variant="standard"
					{...register('email', {
						required: 'Це поле обов\'язкове',
						pattern: {
							value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
							message: 'Неправильний формат електронної пошти'
						}
					})}
					placeholder='Електронна пошта'
					error={!!errors.email}
					helperText={errors.email?.message}
				/>
				{!errors.email?.message && <FormHelperText>
					Для отримання інформації по кредиту
				</FormHelperText>}
			</FormControl>

			<FormControl sx={{ width: { xs: '100%', sm: '48%' } }}>
				<Controller
					name="phone"
					control={control}
					rules={{
						required: ' ',
						validate: (value) => {
							if (value && !matchIsValidTel(value, { onlyCountries: ['UA'] })) {
								return "Неправильний формат номера телефону";
							}
							return true;
						}
					}}
					render={({ field: { ref, value, ...fieldProps }, fieldState }) => (
						<>
							<MuiTelInput
								// Change if need more Countries
								onlyCountries={['UA']}
								defaultCountry='UA'
								disableDropdown
								//
								size='small'
								variant="standard"
								placeholder='+380 95 123 45 67'
								value={value || ''}
								inputRef={ref}
								helperText={fieldState.invalid ? "Неправильний формат номера телефону" : null}
								error={fieldState.invalid}
								{...fieldProps}
							/>
							{!fieldState.invalid && <FormHelperText>
								Для зв'язку
							</FormHelperText>}
						</>
					)}
				/>
			</FormControl>

			<Button pink>Залишити заявку</Button>
		</form>
	)
}

const StudyLoanLeaveRequest = () => {
	const [toggleForm, setToggleForm] = useState(false)
	const [chooseFormId, setChooseFormId] = useState(null)

	const handleToggleForm = (id) => {
		if (chooseFormId === id) {
			setToggleForm(false)
			setChooseFormId(null)
		} else {
			setToggleForm(true)
			setChooseFormId(id)
		}
	}

	return (
		<div className="study_loan_request">
			<div className="study_loan_request__wrapper">
				<div className="container">
					<h2 className="title">Залиште заявку - ми знайдемо найкращі умови навчання для Вас у наших  партнерів</h2>
					<p className="desc">Клікайте на іконку та заповнюйте заявку.</p>

					<div className="study_loan_request__list">
						{list.map(({ id, text, srcImg, altImg }) => (
							<div
								key={`study-loan-form-item-${id}`}
								className="study_loan_request__item"
								style={{
									borderColor: chooseFormId === id ? '#000' : 'transparent'
								}}
								onClick={() => handleToggleForm(id)}
							>
								<div className="study_loan_request__item_img">
									<img src={srcImg} alt={altImg} />
								</div>
								{text}
							</div>
						))}
					</div>

					{toggleForm && chooseFormId && (
						<LeaveRequestForm chooseFormId={chooseFormId} />
					)}
				</div>
			</div>
		</div>
	);
};

export default StudyLoanLeaveRequest;