import React from 'react'

const FamilyLoanHowGet = () => {
	return (
		<div className="family_loan_plans">
			<div className="container">
				<div className="title">Сімейний кредит: фінансування будь-яких планів</div>
				<div className="family_loan_plans__list">
					<div className="family_loan_plans__item">
						<div className="family_loan_plans__title">Кредитна сума від 10 000 грн до 50 000 грн</div>
						<div className="family_loan_plans__text">
							Якщо родині потрібен невеликий кредит, щоб відремонтувати авто чи придбати необхідну для дітей техніку чи ґаджети.
						</div>
						<div className="family_loan_plans__text">Використовуйте промокод</div>
						<div><b>HOME</b></div>
					</div>
					<div className="family_loan_plans__item">
						<div className="family_loan_plans__title">Кредитна сума від 50 000 грн до 100 000 грн</div>
						<div className="family_loan_plans__text">
							Кредит на реалізацію більших планів: зробити вдома ремонт, переїхати в нове місто, заснувати сімейний бізнес.
						</div>
						<div className="family_loan_plans__text">Використовуйте промокод</div>
						<div><b>Family</b></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FamilyLoanHowGet;