import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const SimpleSteps = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">4 ПРОСТІ КРОКИ</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Процедура оформлення кредиту – максимально проста!
                                <br />
                                <br />
                                Отримати кредит від 3000 грн до 6000 грн на будь-які потреби – дуже легко й швидко! Для цього вам необхідно перейти за посиланням <ScrollLink to="/">https://groshi247.com/</ScrollLink> і виконати 4 прості кроки:
                                <br />
                                <br />
                                1️⃣ Обрати суму й строк погашення кредиту за допомогою відповідних “бігунків” і натиснути “Оформити кредит”;
                                <br />
                                <br />
                                2️⃣ Зареєструватися на сайті, пройшовши ідентифікацію через систему BankID, і заповнити заявку;
                                <br />
                                <br />
                                3️⃣ Підписати кредитний договір, ввівши код-ідентифікатор, який ви отримаєте SMS-повідомленням;
                                <br />
                                <br />
                                4️⃣ Подати заявку й отримати рішення!
                                <br />
                                <br />
                                Обробка даних повністю автоматизована, і в разі позитивного рішення по заявці гроші надходять на вашу картку вже через 5 хвилин!
                                <br />
                                <br />
                                Groshi 24/7 – ваш цілодобовий і надійний помічник у світі фінансів
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default SimpleSteps;