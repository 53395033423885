import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const MoneyAutumn = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">ГРОШОВА ОСІНЬ в Groshi 24/7</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Золотої осені не буде! Попереду ГРОШОВА ОСІНЬ з вигодою -50%!
                                <br />
                                <br />
                                Поспішіть підготуватися до осіннього сезону саме зараз, адже ми робимо кредити від 3000 грн ще більш доступними!
                                <br />
                                Розмір кредитної ставки 1% на день – знижено рівно вполовину!
                                <br />
                                <br />
                                Відсьогодні Ви маєте можливість обрати найвигіднішу мікропозику за промокодом N50 під 0,5% на день. Переходьте за посиланням <ScrollLink to="/">https://groshi247.com/</ScrollLink>, заповнюйте Ваші дані, й гроші будуть у Вас на картці вже через 5 хвилин:
                                <br />
                                <br />
                                - без поручителів;
                                <br />
                                - без додаткових комісій;
                                <br />
                                - без купи зайвих документів.
                                <br />
                                <br />
                                Не відкладайте невідкладне, встигніть позичити кошти цією Грошовою ОСІННЮ до завершення акції.
                                <br />
                                Вигідніше не знайдете НІДЕ!
                                <br />
                                <br />
                                Groshi 24/7 – ваш цілодобовий і надійний помічник в світі фінансів.
                                <br />
                                <br />
                                __________
                                <br />
                                <br />
                                Акція діє до 01.09.2024 р. на всій території підконтрольній Україні території, окрім тимчасово окупованих та оточених регіонів, а також районів, де ведуться бойові дії.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default MoneyAutumn;