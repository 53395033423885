import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const NeedMoney = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">Потрібні гроші до 1 вересня?</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                1 вересня — на носі, а грошей нема ще й досі?!
                                <br />
                                <br />
                                Для батьків школярів підготовка до навчального року — наразі найболючіша тема. Шкільні ярмарки хоча й пропонують знижки, але купити треба все й одразу: шкільне приладдя, одяг, взуття й не тільки. Навіть якщо купувати тільки найнеобхідніше і по мінімуму — на це знадобиться чимала сума.
                                <br />
                                <br />
                                Наразі йдеться про витрати, які не можна відкласти на потім, і гроші потрібні прямо зараз. У нас є рішення, яке допоможе вирішити ваші фінансові питання швидко і на вигідних умовах, без поручителів і без візиту до офісу!
                                <br />
                                <br />
                                Пропонуємо скористатися нашим зручним онлайн-сервісом і отримати кошти на картку за лічені хвилини. Для оформлення кредиту вам необхідно:
                                <br />
                                <br />
                                - перейти за посиланням <ScrollLink to="/">https://groshi247.com/</ScrollLink>;
                                <br />
                                - подати заявку, вказавши свої паспортні дані та ІПН.
                                <br />
                                <br />
                                Якщо знадобиться допомога, щоб розібратися з умовами кредитування, наша служба підтримки залюбки проконсультує вас. Телефонуйте:  0 800 21 2424 з 09:00 до 18:00, пн-пт.
                                <br />
                                <br />
                                Діти — це найдорожче, і кожен із батьків намагається дати дитині все найкраще! Groshi 24/7 допоможуть реалізувати всі мрії ваших дітей тут і зараз!
                                <br />
                                <br />
                                Groshi 24/7 – ваш цілодобовий і надійний помічник в світі фінансів.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default NeedMoney;