import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const MoneyBeforeSalary = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">Наші переваги</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Гроші онлайн – до зарплати не чекай!
                                <br />
                                <br />
                                Життєві обставини інколи диктують свої умови, коли гроші необхідні тут і зараз, і чекати до зарплати – не варіант.
                                <br />
                                <br />
                                Пропонуємо оптимальне рішення – оформити мікрокредит на 3000 - 6000 грн в Groshi 24/7:
                                <br />
                                - миттєво;
                                <br />
                                - онлайн;
                                <br />
                                - без візиту до офісу.
                                <br />
                                <br />
                                Ми гарантуємо:
                                <br />
                                - швидкість: гроші будуть у вас на картці через 5 хвилин завдяки автоматизованій обробці заявок;
                                <br />
                                - безпеку та конфіденційність: вам не потрібні поручителі, а ваші персональні дані захищені міжнародними сертифікатами безпеки;
                                <br />
                                - лояльність: можливість пролонгації кредиту, вигідні дисконтні пропозиції та індивідуальний підхід до кожного клієнта.
                                <br />
                                <br />
                                Кредит до зарплати може отримати будь-яка особа віком від 18 до 60 років, яка має банківську картку, мобільний телефон чи комп'ютер з виходом в Інтернет, паспорт та ІПН.
                                <br />
                                <br />
                                Докладніше на сайті  <ScrollLink to="/">https://groshi247.com/</ScrollLink>, переходьте, натискайте кнопку “Отримати кредит”, заповнюйте анкету та отримуйте гроші протягом 5 хвилин на картку.
                                <br />
                                <br />
                                Groshi 24/7 – ваш цілодобовий і надійний помічник в світі фінансів.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default MoneyBeforeSalary;