import React from 'react'

import checkedItem from '../../../assets/checked_item.svg'

const FamilyLoanWhyProfitableTake = () => {
	return (
		<div className="study_loan_why_profitable_take_loan">
			<div className="study_loan_why_profitable_take_loan__wrapper" style={{ backgroundColor: 'rgb(252, 249, 247)' }}>
				<div className="container">
					<div className="title">
						Чому вигідно брати<br />
						сімейний кредит в <span>Groshi 24/7?</span>
					</div>

					<div className="study_loan_why_profitable_take_loan__list">
						<div className="study_loan_why_profitable_take_loan__item">
							<img src={checkedItem} alt="checked item" />
							Швидке схвалення<br />
							кредиту
						</div>
						<div className="study_loan_why_profitable_take_loan__item">
							<img src={checkedItem} alt="checked item" />
							Мінімальний пакет<br />
							документів
						</div>
						<div className="study_loan_why_profitable_take_loan__item">
							<img src={checkedItem} alt="checked item" />
							Вигідні процентні<br />
							ставки
						</div>
						<div className="study_loan_why_profitable_take_loan__item">
							<img src={checkedItem} alt="checked item" />
							Без додаткових<br />
							комісій
						</div>
						<div className="study_loan_why_profitable_take_loan__item">
							<img src={checkedItem} alt="checked item" />
							Оформлення кредиту<br />
							- безкоштовно
						</div>
						<div className="study_loan_why_profitable_take_loan__item">
							<img src={checkedItem} alt="checked item" />
							Гнучке погашення
						</div>
						<div className="study_loan_why_profitable_take_loan__item">
							<img src={checkedItem} alt="checked item" />
							Повністю онлайн,<br />
							без візиту до офісу
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FamilyLoanWhyProfitableTake;