import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ScrollLink from "../lib/helpers/ScrollLink";
import Reviews from "../components/Reviews/Reviews";

const ReviewsPage = () => {
    return (
        <div className="reviews_page">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="reviews" />
                    <div className="reviews_page__wrapper">
                        <h1 className="title">Всі відгуки</h1>
                        <Reviews
                            title="Відгуки кредиту на навчання"
                            backgroundColor="transparent"
                            list={[
                                { sender: 'Олексій', text: 'Я вирішив змінити професію та вступити на довготривалі курси з IT, але зіткнувся з фінансовими труднощами. Банк відмовив у наданні кредиту, і я вже не знав, що робити. На щастя, в інтернеті я натрапив на цей сервіс Groshi 247  і вирішив спробувати. Усе виявилося дуже просто – заявку на кредит для студентів я оформив онлайн, а кошти на картку отримав у найкоротший термін. Умови прозорі, відсотки доступні, а графік погашення зручний. Завдяки цьому сервісу я зміг оплатити курси та тепер маю шанс реалізувати свої мрії в новій професії. Дуже вдячний і рекомендую всім, хто опинився у схожій ситуації', isShowBtn: true },
                                { sender: 'Інна', text: 'Хочу поділитися позитивним досвідом! Вирішила вивчати англійську для кар\'єрного росту, але курси виявилися досить дорогими. Завдяки сервісу Groshi 247 змогла отримати кредит без зайвих затримок. Усе прозоро, швидко і дуже зручно. Курси вже оплачено, і тепер я рухаюся до своїх цілей. Велике дякую за допомогу', isShowBtn: false },
                                { sender: 'Андрій', text: 'Я переїхав із сходу країни та вирішив змінити професію – хочу стати вебдизайнером. На початку навчання мав гроші лише на перший семестр, сподівався зібрати необхідну суму з допомогою друзів, але не вдалося. Ситуація стала критичною – без оплати я б не зміг продовжити навчання. На щастя, я знайшов цей сервіс Groshi 247 в інтернеті. Після розмови з менеджером вони запропонували варіант із додаванням поручителя, і один із моїх друзів погодився допомогти. Завдяки цьому я отримав необхідні кошти та зміг продовжити навчання. Дуже вдячний за таку можливість! Сподіваюся завершити навчання та знайти хорошу роботу. Дякую вам за підтримку у складний момент', isShowBtn: true },
                                { sender: 'Лера', text: 'Я завжди любила собачок і мріяла про роботу, пов’язану з доглядом за домашніми улюбленцями. Але мені бракувало знань та навичок для цього. Зараз я працюю касиром у супермаркеті, але дуже хотіла змінити своє життя. Знайшла чудову школу, де навчають догляду за домашніми тваринами, але потрібні були гроші, щоб оплатити навчання. Моя сестра знайшла цей сервіс в інтернеті, і завдяки їм ми отримали кредит на мій курс. Сестра стала поручителем, і все пройшло дуже легко та швидко. Тепер я щаслива, що зможу реалізувати свою мрію та працювати улюблену справу завдяки цій допомозі. Дякую від щирого серця', isShowBtn: true }
                            ]}
                        />

                        <Reviews
                            title="Відгуки сімейного кредиту"
                            backgroundColor="transparent"
                            list={[
                                { sender: 'Oксана', text: 'У нашій сім’ї з’явилася друга дитина, і ми вирішили зробити ремонт у будинку, щоб створити старшій дитині її особистий простір. Проте будівельні матеріали зараз дуже дорогі, і нам була потрібна фінансова допомога. Ми звернулися до цього сервісу, і вони допомогли нам отримати кредит на ремонт. Усе пройшло швидко та без зайвих складнощів. Тепер у нашій оселі стало більше затишку, і кожен має свій куточок. Ми дуже вдячні за вашу допомогу! Вся сім’я щаслива, дякуємо цьому сервісу за підтримку у важливий момент нашого життя', isShowBtn: true },
                                { sender: 'Віталій', text: 'Іноді трапляється неприємність – дорожньо-транспортна пригода. На жаль, це сталося і зі мною. Це був великий удар, адже я виявився винним і змушений був відремонтувати не лише свою машину, але й чужу. Це була значна сума для мене, яка стала серйозним випробуванням для сімейного бюджету. На щастя, я звернувся до цього сервісу, і вони допомогли мені отримати кредит. Завдяки їхній підтримці, протягом 4 місяців я впорався з усіма витратами та зміг забезпечити стабільність у сім\'ї. Щиро вдячний за допомогу у такий важкий час', isShowBtn: true },
                                { sender: 'Лукьян', text: 'Ви знаєте, як важко сьогодні зі світлом. Моя дружина і я працюємо віддалено, часто з клієнтами з різних країн, тому нам потрібно завжди залишатися на зв\'язку. Щоб забезпечити незалежність від перебоїв зі світлом, я вирішив придбати генератор для нашої родини. Це було нелегке рішення, адже генератор коштує чимало. Переді мною став вибір – залишитися без світла і без роботи або оплатити свою незалежність. Я вирішив платити і звернувся до цього сервісу. Підготував усі необхідні документи, і мені швидко виділили кредит. Тепер я впевнений, що зможу вчасно виконувати свою роботу, незалежно від обставин. Дякую сервісу за допомогу і щиро раджу всім, хто опинився у подібній ситуації, звертатися до них', isShowBtn: true }
                            ]}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReviewsPage
