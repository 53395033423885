import React from 'react'

import simpleStep1 from '../../../assets/familyLoan/simple_step_1.png'
import simpleStep2 from '../../../assets/familyLoan/simple_step_2.png'
import simpleStep3 from '../../../assets/familyLoan/simple_step_3.png'
import simpleStep4 from '../../../assets/familyLoan/simple_step_4.png'
import simpleStep5 from '../../../assets/familyLoan/simple_step_5.png'

const FamilyLoanSimpleSteps = () => {
	return (
		<div className="family_loan_simple_steps">
			<div className="container">
				<div className="title">Прості кроки для отримання кредиту</div>
				<div className="family_loan_simple_steps__list">
					<div className="family_loan_simple_steps__item">
						<div className="family_loan_simple_steps__img">
							<img src={simpleStep1} alt="simple step 1" />
						</div>
						<div className="family_loan_simple_steps__title">1.</div>
						<div className="family_loan_simple_steps__title">
							Заповніть<br /> анкету
						</div>
					</div>
					<div className="family_loan_simple_steps__item">
						<div className="family_loan_simple_steps__img">
							<img src={simpleStep2} alt="simple step 2" />
						</div>
						<div className="family_loan_simple_steps__title">2.</div>
						<div className="family_loan_simple_steps__title">
							Залучіть<br /> поручителя
						</div>
					</div>
					<div className="family_loan_simple_steps__item">
						<div className="family_loan_simple_steps__img">
							<img src={simpleStep3} alt="simple step 3" />
						</div>
						<div className="family_loan_simple_steps__title">3.</div>
						<div className="family_loan_simple_steps__title">
							Отримайте<br /> схвалення
						</div>
					</div>
					<div className="family_loan_simple_steps__item">
						<div className="family_loan_simple_steps__img">
							<img src={simpleStep4} alt="simple step 4" />
						</div>
						<div className="family_loan_simple_steps__title">4.</div>
						<div className="family_loan_simple_steps__title">
							Підпишіть<br /> договір<br /> онлайн
						</div>
					</div>
					<div className="family_loan_simple_steps__item">
						<div className="family_loan_simple_steps__img">
							<img src={simpleStep5} alt="simple step 5" />
						</div>
						<div className="family_loan_simple_steps__title">5.</div>
						<div className="family_loan_simple_steps__title">
							Отримайте<br /> кошти
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FamilyLoanSimpleSteps;