import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const TeacherSchool = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">"Здайте гроші на..."</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Витрати, які не закінчуються ніколи!
                                <br />
                                <br />
                                Спорожнілі гаманці батьків ще не встигли наповнитися після чималих витрат до 1 вересня, як знову треба скидатися... Цього разу на подарунки вчителям. Батьківсьий чат розривається від повідомлень: голосують за те, ЩО дарувати, по скільки скидатися, вітати тільки свого вчителя, чи вчителів з усіх предметів і директора школи в тому числі?!
                                <br />
                                <br />
                                А окрім школи, найближчим часом ще купа додаткових витрат - взуття на осінь, комуналка, автоцивілка, День народження дитини, кума, похресниці тощо. І якось так виходить - що ВСЕ й ОДРАЗУ!
                                <br />
                                <br />
                                Знайомо?
                                <br />
                                <br />
                                Якщо ситуація критична й витрати сніжним комом навалюються на вас, а до зарплати ще далеко, є вихід - можна взяти онлайн позику в Groshi 24/7. Пропонуємо вигідні умови кредитування, доладніше тут 🔗 <ScrollLink to="/">https://groshi247.com/</ScrollLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default TeacherSchool;