import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import TermsUsingCredit from "../components/TermsUsingCredit/TermsUsingCredit";
import fastestDeliveryIcon from "../assets/fastest-delivery.png";
import percentIcon from "../assets/percent.png";
import phoneIcon from "../assets/phone.png";
import passportIcon from "../assets/passport.png";
import teamIcon from "../assets/business-team.png";
import shieldIcon from "../assets/shield.png";
import moneyIcon from "../assets/money.png";
import carIcon from "../assets/car.png";
import carImage from "../assets/auto_lending.jpg";
import carImageMobile from "../assets/auto_lending_mobile.jpg";
import creditPayLesImageWebp from "../assets/gr_image4.webp";
import creditPayLesImageMobile from "../assets/credit_pay_les_lending_mobile.jpg";
import userCreditIcon from "../assets/user_credit.png";
import cardCreditIcon from "../assets/card_credit.png";
import phoneCreditIcon from "../assets/phone_credit.png";
import moneyCreditIcon from "../assets/money_credit.png";
import loanRepaymentImageWebp from "../assets/gr_image6.webp";
import zmiIcon from "../assets/zmi_img.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ScrollLink from "../lib/helpers/ScrollLink";

const Home = () => {

    return (
        <div>
            <div className="top_background">
                <div className="container">
                    <Header activePageName="/home" />
                    <div className="block_register">
                        <p className="block_register__title">Ваша фінансова <br/>
                            свобода</p>
                        <span className="block_register__desc">Онлайн кредит на картку за 5 хвилин</span>
                        <button className="btn btn__pink btn__big">
                            <Link
                                to="https://cab.groshi247.com/signup"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Оформити кредит
                            </Link>
                        </button>
                    </div>
                    <div className="our_products">
                        <div className="our_products__title">Наші продукти</div>
                        <Swiper
                            modules={[Navigation, Pagination, A11y]}
                            spaceBetween={5}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1
                                },
                                418: {
                                    slidesPerView: 2
                                }
                            }}
                        >
                            <SwiperSlide>
                                <div className="our_products__item">
                                    <div className="our_products__item_title">Кредит до зарплати</div>
                                    <div className="our_products__item_desc">
                                        для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                    </div>
                                    <Link className="btn btn__pink" to="/">Детальніше</Link>
                                </div>
                            </SwiperSlide>
                            {/*<SwiperSlide>*/}
                            {/*    <div className="our_products__item">*/}
                            {/*        <div className="our_products__item_title">Персональний кредит</div>*/}
                            {/*        <div className="our_products__item_desc">*/}
                            {/*            терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*        </div>*/}
                            {/*        <Link className="btn btn__pink" to="/personal-loan">Детальніше</Link>*/}
                            {/*    </div>*/}
                            {/*</SwiperSlide>*/}
                            {/*<SwiperSlide>*/}
                            {/*    <div className="our_products__item">*/}
                            {/*        <div className="our_products__item_title">Автокредит</div>*/}
                            {/*        <div className="our_products__item_desc">*/}
                            {/*            терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*        </div>*/}
                            {/*        <Link className="btn btn__pink" to="/car-loan">Детальніше</Link>*/}
                            {/*    </div>*/}
                            {/*</SwiperSlide>*/}
                            {/*<SwiperSlide>
                                <div className="our_products__item">
                                    <div className="our_products__item_title">Кредити бізнесу</div>
                                    <div className="our_products__item_desc">
                                        терміном на кілька місяців на купівлю побутової техніки, одягу
                                    </div>
                                    <Link className="btn btn__pink" to="/business-loan">Детальніше</Link>
                                </div>
                            </SwiperSlide>*/}
                            {/*<SwiperSlide>*/}
                            {/*    <div className="our_products__item">*/}
                            {/*        <div className="our_products__item_title">Рефінансування кредиту</div>*/}
                            {/*        <div className="our_products__item_desc">*/}
                            {/*            для фізосіб, без конкретних потреб, терміном до 1 місяця.*/}
                            {/*        </div>*/}
                            {/*        <Link className="btn btn__pink" to="/car-loan">Детальніше</Link>*/}
                            {/*    </div>*/}
                            {/*</SwiperSlide>*/}
                            <SwiperSlide>
                                <div className="our_products__item">
                                    <div className="our_products__item_title">Кредит на навчання</div>
                                    <div className="our_products__item_desc">
                                        Ваш шлях до нової професії
                                    </div>
                                    <Link className="btn btn__pink" to="/student-loan">Детальніше</Link>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="our_products__item">
                                    <div className="our_products__item_title">Сімейний кредит</div>
                                    <div className="our_products__item_desc">
                                        Реальна можливість змін на краще
                                    </div>
                                    <Link className="btn btn__pink" to="/family-loan">Детальніше</Link>
                                </div>
                            </SwiperSlide>
                            {/*<SwiperSlide>*/}
                            {/*    <div className="our_products__item">*/}
                            {/*        <div className="our_products__item_title">Кредит на медичні послуги</div>*/}
                            {/*        <div className="our_products__item_desc">*/}
                            {/*            терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*        </div>*/}
                            {/*        <Link className="btn btn__pink" to="/medical-services">Детальніше</Link>*/}
                            {/*    </div>*/}
                            {/*</SwiperSlide>*/}
                            {/*<SwiperSlide>*/}
                            {/*    <div className="our_products__item">*/}
                            {/*        <div className="our_products__item_title">Страхові послуги</div>*/}
                            {/*        <div className="our_products__item_desc">*/}
                            {/*            терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*        </div>*/}
                            {/*        <Link className="btn btn__pink" to="/insurance-services">Детальніше</Link>*/}
                            {/*    </div>*/}
                            {/*</SwiperSlide>*/}
                        </Swiper>
                        <div className="our_products__list">
                            <div className="our_products__item">
                                <div className="our_products__item_title">Кредит до зарплати</div>
                                <div className="our_products__item_desc">
                                    для фізосіб, без конкретних потреб, терміном до 1 місяця.
                                </div>
                                <ScrollLink className="btn btn__pink" to="/">Детальніше</ScrollLink>
                            </div>
                            {/*<div className="our_products__item">*/}
                            {/*    <div className="our_products__item_title">Персональний кредит</div>*/}
                            {/*    <div className="our_products__item_desc">*/}
                            {/*        терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*    </div>*/}
                            {/*    <Link className="btn btn__pink" to="/personal-loan">Детальніше</Link>*/}
                            {/*</div>*/}
                            {/*<div className="our_products__item">*/}
                            {/*    <div className="our_products__item_title">Автокредит</div>*/}
                            {/*    <div className="our_products__item_desc">*/}
                            {/*        терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*    </div>*/}
                            {/*    <Link className="btn btn__pink" to="/car-loan">Детальніше</Link>*/}
                            {/*</div>*/}
                            {/*<div className="our_products__item">
                                <div className="our_products__item_title">Кредити бізнесу</div>
                                <div className="our_products__item_desc">
                                    терміном на кілька місяців на купівлю побутової техніки, одягу
                                </div>
                                <Link className="btn btn__pink" to="/business-loan">Детальніше</Link>
                            </div>*/}
                            {/*<div className="our_products__item">*/}
                            {/*    <div className="our_products__item_title">Рефінансування кредиту</div>*/}
                            {/*    <div className="our_products__item_desc">*/}
                            {/*        для фізосіб, без конкретних потреб, терміном до 1 місяця.*/}
                            {/*    </div>*/}
                            {/*    <Link className="btn btn__pink" to="/refinancing-loan">Детальніше</Link>*/}
                            {/*</div>*/}
                            <div className="our_products__item">
                                <div className="our_products__item_title">Кредит на навчання</div>
                                <div className="our_products__item_desc">
                                    Ваш шлях до нової професії
                                </div>
                                <ScrollLink className="btn btn__pink" to="/student-loan">Детальніше</ScrollLink>
                            </div>
                            <div className="our_products__item">
                                <div className="our_products__item_title">Сімейний кредит</div>
                                <div className="our_products__item_desc">
                                    Реальна можливість змін на краще
                                </div>
                                <ScrollLink className="btn btn__pink" to="/family-loan">Детальніше</ScrollLink>
                            </div>
                            {/*<div className="our_products__item">*/}
                            {/*    <div className="our_products__item_title">Кредит на медичні послуги</div>*/}
                            {/*    <div className="our_products__item_desc">*/}
                            {/*        терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*    </div>*/}
                            {/*    <Link className="btn btn__pink" to="/medical-services">Детальніше</Link>*/}
                            {/*</div>*/}
                            {/*<div className="our_products__item">*/}
                            {/*    <div className="our_products__item_title">Страхові послуги</div>*/}
                            {/*    <div className="our_products__item_desc">*/}
                            {/*        терміном на кілька місяців на купівлю побутової техніки, одягу*/}
                            {/*    </div>*/}
                            {/*    <Link className="btn btn__pink" to="/insurance-services">Детальніше</Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="get_loan">
                <div className="container">
                    <div className="get_loan__block">
                        <div className="get_loan__title">Як отримати кредит?</div>
                        {/* <div className="get_loan__desc"> */}
                        {/*    Щоб взяти кредит онлайн на картку, ми не ставимо жорстких обмежень та умов і готові видати Вам кредит, якщо */}
                        {/* </div> */}
                    </div>
                    <Swiper
                        modules={[Navigation, Pagination, A11y]}
                        spaceBetween={10}
                        slidesPerView={1.5}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        breakpoints={{
                            0: {
                                slidesPerView: 1
                            },
                            418: {
                                slidesPerView: 2
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={fastestDeliveryIcon} alt="icon 1" />
                                <div className="get_loan__item_text">Швидке схвалення кредиту</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={percentIcon} alt="icon 2" />
                                <div className="get_loan__item_text">Дострокове<br/> погашення без комісій</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={phoneIcon} alt="icon 3" />
                                <div className="get_loan__item_text">Онлайн оформлення кредиту</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={passportIcon} alt="icon 4" />
                                <div className="get_loan__item_text">Мінімум<br/> документів</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={teamIcon} alt="icon 5" />
                                <div className="get_loan__item_text">Зниження ставок для постійних клієнтів</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={shieldIcon} alt="icon 6" />
                                <div className="get_loan__item_text">Захист Ваших<br/>  даних</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={moneyIcon} alt="icon 7" />
                                <div className="get_loan__item_text">Сплачуй кредит в своєму темпі</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={percentIcon} alt="icon 8" />
                                <div className="get_loan__item_text">Дострокове<br/> погашення без комісій</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item">
                                <img src={carIcon} alt="icon 9" />
                                <div className="get_loan__item_text">Акції</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="get_loan__list">
                        <div className="get_loan__item">
                            <img src={fastestDeliveryIcon} alt="icon 1" />
                            <div className="get_loan__item_text">Швидке схвалення кредиту</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={percentIcon} alt="icon 2" />
                            <div className="get_loan__item_text">Дострокове<br/> погашення без комісій</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={phoneIcon} alt="icon 3" />
                            <div className="get_loan__item_text">Онлайн оформлення кредиту</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={passportIcon} alt="icon 4" />
                            <div className="get_loan__item_text">Мінімум<br/> документів</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={teamIcon} alt="icon 5" />
                            <div className="get_loan__item_text">Зниження ставок для постійних клієнтів</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={shieldIcon} alt="icon 6" />
                            <div className="get_loan__item_text">Захист Ваших<br/>  даних</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={moneyIcon} alt="icon 7" />
                            <div className="get_loan__item_text">Сплачуй кредит в своєму темпі</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={percentIcon} alt="icon 8" />
                            <div className="get_loan__item_text">Дострокове<br/> погашення без комісій</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={carIcon} alt="icon 9" />
                            <div className="get_loan__item_text">Акції</div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="car_loan_programs">*/}
            {/*    <div className="container container__w1400">*/}
            {/*        <div className="car_loan_programs__wrapper">*/}
            {/*            <div className="car_loan_programs__block_img">*/}
            {/*                <img className="background_img__table" src={carImage} alt="car" />*/}
            {/*                <img className="background_img__mobile" src={carImageMobile} alt="mobile car" />*/}
            {/*            </div>*/}
            {/*            <div className="car_loan_programs__info">*/}
            {/*                <div className="title">Вигідні програми автокредитування</div>*/}
            {/*                <div className="desc">*/}
            {/*                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam*/}
            {/*                </div>*/}
            {/*                <div className="btn__group">*/}
            {/*                    <button className="btn btn__pink">Як це працює</button>*/}
            {/*                    <button className="btn btn__green">Розрахувати кредит</button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="pay_les">*/}
            {/*    <div className="container">*/}
            {/*        <div className="title">*/}
            {/*            Рефінансування кредиту<br/> - плати менше*/}
            {/*        </div>*/}
            {/*        <div className="desc">*/}
            {/*            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam*/}
            {/*        </div>*/}
            {/*        <div className="btn__group">*/}
            {/*            <button className="btn btn__pink">Як це працює</button>*/}
            {/*            <button className="btn btn__green">Розрахувати кредит</button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="background_img">
                <img className="background_img__table" src={creditPayLesImageWebp} alt="Credit pay les icon"/>
                <img className="background_img__mobile" src={creditPayLesImageMobile} alt="Credit pay les mobile icon"/>
            </div>

            <div className="loan_repayment">
                <div className="container">
                    <div className="title">Погаси  кредит онлайн через  особистий кабінет  не виходячи з дому.</div>
                    <div className="loan_repayment__list">
                        <div className="loan_repayment__item">
                            <img src={userCreditIcon} alt="icon 1" />
                            <div className="loan_repayment__text">В особистому  кабінеті</div>
                        </div>
                        <div className="loan_repayment__item">
                            <img src={cardCreditIcon} alt="icon 2" />
                            <div className="loan_repayment__text">Через  платіжні термінали</div>
                        </div>
                        <div className="loan_repayment__item">
                            <img src={phoneCreditIcon} alt="icon 3" />
                            <div className="loan_repayment__text">Сплатити за номером  телефону</div>
                        </div>
                        <div className="loan_repayment__item">
                            <img src={moneyCreditIcon} alt="icon 4" />
                            <div className="loan_repayment__text">У касі  банків-партнерів</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="background_img">
                <img className="background_img__table" src={loanRepaymentImageWebp} alt="Loan repayment icon"/>
            </div>

            {/* <div className="zmi">
                <div className="container">
                    <div className="title">Ми в ЗМІ</div>
                    <Swiper
                        modules={[Navigation, Pagination, A11y]}
                        spaceBetween={10}
                        slidesPerView={1.5}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        breakpoints={{
                            0: {
                                slidesPerView: 1
                            },
                            418: {
                                slidesPerView: 2
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div className="zmi__item">
                                <div className="zmi__block_img">
                                    <img src={zmiIcon} alt="icon zmi 1" />
                                </div>
                                <div className="zmi__title">
                                    Стаття 1 Lorem ipsum dolor sit amet, consectetur adipisicing
                                </div>
                                <div className="zmi__desc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="zmi__item">
                                <div className="zmi__block_img">
                                    <img src={zmiIcon} alt="icon zmi 2" />
                                </div>
                                <div className="zmi__title">
                                    Стаття 2
                                </div>
                                <div className="zmi__desc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="zmi__item">
                                <div className="zmi__block_img">
                                    <img src={zmiIcon} alt="icon zmi 3" />
                                </div>
                                <div className="zmi__title">
                                    Стаття 3
                                </div>
                                <div className="zmi__desc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="zmi__item">
                                <div className="zmi__block_img">
                                    <img src={zmiIcon} alt="icon zmi 4" />
                                </div>
                                <div className="zmi__title">
                                    Стаття 4
                                </div>
                                <div className="zmi__desc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                                <button className="btn btn__pink">Читати далі</button>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="zmi__list">
                        <div className="zmi__item">
                            <div className="zmi__block_img">
                                <img src={zmiIcon} alt="icon zmi 1" />
                            </div>
                            <div className="zmi__title">
                                Стаття 1 Lorem ipsum dolor sit amet, consectetur adipisicing
                            </div>
                            <div className="zmi__desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                            <button className="btn btn__pink">Читати далі</button>
                        </div>
                        <div className="zmi__item">
                            <div className="zmi__block_img">
                                <img src={zmiIcon} alt="icon zmi 2" />
                            </div>
                            <div className="zmi__title">
                                Стаття 2
                            </div>
                            <div className="zmi__desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                            <button className="btn btn__pink">Читати далі</button>
                        </div>
                        <div className="zmi__item">
                            <div className="zmi__block_img">
                                <img src={zmiIcon} alt="icon zmi 3" />
                            </div>
                            <div className="zmi__title">
                                Стаття 3
                            </div>
                            <div className="zmi__desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                            <button className="btn btn__pink">Читати далі</button>
                        </div>
                        <div className="zmi__item">
                            <div className="zmi__block_img">
                                <img src={zmiIcon} alt="icon zmi 4" />
                            </div>
                            <div className="zmi__title">
                                Стаття 4
                            </div>
                            <div className="zmi__desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                            <button className="btn btn__pink">Читати далі</button>
                        </div>
                    </div>
                </div>
            </div> */}

            <TermsUsingCredit />

            <Footer />
        </div>
    );
};

export default Home;