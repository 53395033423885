import { useEffect } from "react"
import moment from "moment";

export const useSearchParams = () => {
	const queryParameters = new URLSearchParams(window.location.search)
	const utm_campaign = queryParameters.get("utm_campaign")
	const utm_source = queryParameters.get("utm_source")
	const utm_medium = queryParameters.get("utm_medium")
	const aff_sub = queryParameters.get("aff_sub")

	useEffect(() => {
		if (utm_campaign) {
			localStorage.setItem('utm_campaign', utm_campaign)
		}
		if (utm_source) {
			localStorage.setItem('utm_source', utm_source)
		}
		if (utm_medium) {
			localStorage.setItem('utm_medium', utm_medium)
		}
		if (aff_sub) {
			localStorage.setItem('aff_sub', aff_sub)
		}
		if (utm_campaign || utm_source || utm_medium || aff_sub) {
			localStorage.setItem('timeWrite', moment(new Date()).format())
		}
	}, [utm_campaign, utm_source, utm_medium, aff_sub])

	return queryParameters
}
