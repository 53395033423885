import React, {useEffect} from "react";
import {RouterProvider} from "react-router-dom";
import Favicon from "react-favicon";
import routers from "./routers";
import "./App.scss";
import faicon_img from "./assets/favicon/logo.png";
import TawkToChat from "./components/TawkToChat/TawkToChat";
import PushNotificationModal from "./components/PushNotificationModal/PushNotificationModal";
import ScrollToTop from "react-scroll-to-top";
import {useSearchParams} from "./lib/hooks/useSearchParams";
import moment from "moment";

function App() {
	const searchParams = useSearchParams()

	useEffect(() => {
		const timeWrite = localStorage.getItem('timeWrite')

		if (timeWrite) {
			const parseTimeWrite = moment(timeWrite).add(1, 'day').format('DD.MM.YYYY HH:mm:ss')
			const now = moment(new Date()).format('DD.MM.YYYY HH:mm:ss')

			if (parseTimeWrite < now) {
				localStorage.removeItem('utm_campaign')
				localStorage.removeItem('utm_source')
				localStorage.removeItem('utm_medium')
				localStorage.removeItem('aff_sub')
				localStorage.removeItem('timeWrite')
			}
		}
	}, [])

	return (
		<>
			<Favicon url={faicon_img}/>
			<RouterProvider router={routers}/>
			<TawkToChat/>
			<ScrollToTop smooth/>
			{/* <PushNotificationModal /> */}
		</>
	)
}

export default App;
