import React, {useEffect, useRef, useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Reviews.scss'
import Button from "../Common/Button";

const ReviewItem = ({ review }) => {
    const [showAll, setShowAll] = useState(false)
    const [height, setHeight] = useState('310px')

    const toggleShowAll = () => setShowAll(!showAll)
    const handleChangeHeight = (value) => setHeight(value)

    return (
       <div>
           <div className="reviews__item" style={{ height }}>
               <div className="reviews__header">{review.sender}</div>
               <div className="reviews__body">
                   {review.text}
               </div>
               {review.isShowBtn && (
                   <div className="reviews__footer">
                       <Button
                           noBg
                           onClick={() => {
                               toggleShowAll()
                               handleChangeHeight(height === '100%' ? '310px' : '100%')
                           }}
                       >
                           {showAll ? 'Приховати' : 'Читати далі'}
                       </Button>
                   </div>
               )}
           </div>
       </div>
    )
}

export default ReviewItem
