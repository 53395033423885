import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Reviews.scss'
import ReviewItem from "./ReviewItem";

const Reviews = ({ backgroundColor = '#fcf0eb', list = [], title = 'Відгуки' }) => {
    if (list?.length < 0) return null

    return (
        <div className="reviews" style={{ backgroundColor }}>
            <div className="container">
                <div className="title">{title}</div>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                    className="reviews__list"
                    breakpoints={{
                        769: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        }
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {list.map((review, index) => (
                        <SwiperSlide key={`review-slider-item-${index}`}>
                            <ReviewItem review={review} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Reviews
