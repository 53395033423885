import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const DocumentsAreRequired = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">Які документи потрібні для оформлення кредиту?</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Якщо ви плануєте взяти позику в банку, вам необхідно буде зібрати чималий пакет документів. Від вас вимагатимуть довідки про доходи, про працевлаштування, витяги з банківських рахунків тощо.
                                <br />
                                <br />
                                Але іноді виникають форс-мажорні ситуації, коли часу обмаль і збирати довідки НІКОЛИ! Groshi 24/7 – це сервіс онлайн кредитів, який допоможе вирішити ваші фінансові труднощі тут і ЗАРАЗ!
                                <br />
                                <br />
                                Для отримання кредитних коштів вам знадобиться тільки паспорт громадянина/ громадянки України та ідентифікаційний код. Подача заявки на отримання кредиту займе мінімум часу – вже через 5 хвилин ви отримаєте гроші на банківську картку.
                                <br />
                                <br />
                                Переходьте за посиланням <ScrollLink to="/">https://groshi247.com/</ScrollLink> і переконайтеся, що кредит – це просто й швидко!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default DocumentsAreRequired;